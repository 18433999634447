import React from 'react';
import { MdHttp} from 'react-icons/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ViewBusinessFormCopySocialCardLinkButtonIcon.css'

toast.configure()
function ViewBusinessFormCopySocialCardLinkButtonIcon() {

    const notify = () => {
        navigator.clipboard.writeText(window.location.href)
        toast.success('social card link copied to clipboard',
                        {position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 4000})
    }
    return (
        <div>
            <button
            type='button'
            className='view-business-form-http-link-icon-button'
            onClick={notify}
            ><MdHttp /></button>
        </div>
    );
}

export default ViewBusinessFormCopySocialCardLinkButtonIcon;