import React from 'react';
import { Formik, Form } from 'formik'
import './HomeForm.css'
import HomeFormAddBusinessLinkButton from './HomeFormAddBusinessLinkButton'
import HomeFormFindBusinessLinkButton from './HomeFormFindBusinessLinkButton'
import HomeFormUpdateBusinessLinkButton from './HomeFormUpdateBusinessLinkButton'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import HomeFormLogOutLinkButton from './HomeFormLogOutLinkButton'
import HomeFormUpdateProfileLinkButton from './HomeFormUpdateProfileLinkButton'
import { useAuth } from '../../contexts/AuthContext';
//import ReactPlayer from 'react-player'

function HomeForm(props) {
    const { currentUser, updateDisplayName } = useAuth()

    if (!currentUser.displayName) {
        const { name } = props.location
        console.log('name from signup')
        console.log(name)
        updateDisplayName(name)
    }


    return (
        <Formik>
            {
                //formik => {
                //return
                <Form className='home-form'>
                    <div className='home-form-retrun-home-message-div'>
                        Click SMITHBOOK anytime to return home.
                    </div>
                    <AppLogoLinkButtonToHome />
                    <center><div className='home-form-image-div' /></center>
                    <div className='home-form-add-business-link-button-div'><HomeFormAddBusinessLinkButton /></div>
                    <div className='home-form-find-business-link-button-div'><HomeFormFindBusinessLinkButton /></div>
                    <div className='home-form-update-business-link-button-div'><HomeFormUpdateBusinessLinkButton /></div>
                    <div className='home-form-update-profile-link-button-div'><HomeFormUpdateProfileLinkButton /></div>
                    <div className='home-form-logout-link-button-div'><HomeFormLogOutLinkButton /></div>
                </Form>
                //}
            }
        </Formik>
    );
}

export default HomeForm;
/*
<div className='home-form-react-player-div'>
<center><ReactPlayer url='https://www.youtube.com/watch?v=MV4Nq1GaIAA' height='200px' width='100%' /></center>
</div>
*/