import React from 'react';
import { Link } from 'react-router-dom'
import { TiSocialYoutube } from 'react-icons/ti'
import './ViewBusinessFormViewYouTubeIconLinkButton.css'

function ViewBusinessFormViewYouTubeIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.youtube.com/' + window.localStorage.getItem('youtube').substring(1)
             }} ><button type='button' className='view-business-form-youtube-icon-link-button'><TiSocialYoutube /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewYouTubeIconLinkButton;