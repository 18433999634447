import React from 'react';
import { Link } from 'react-router-dom'
import { TiSocialFacebook} from 'react-icons/ti'
import './ViewBusinessFormViewFaceBookIconLinkButton.css'

function ViewBusinessFormViewFaceBookIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.facebook.com/' + window.localStorage.getItem('facebook').substring(1)
             }} ><button type='button' className='view-business-form-facebook-icon-link-button'><TiSocialFacebook /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewFaceBookIconLinkButton;