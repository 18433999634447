import React from 'react';
import { Link } from 'react-router-dom'

function HomeFormFindBusinessLinkButton() {
    return (
        <div>
            <Link to='/findbusiness'><button className='home-form-find-business-link-button'>Find Business</button></Link>
        </div>
    );
}

export default HomeFormFindBusinessLinkButton;