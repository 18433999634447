import React from 'react';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HiOutlineMail } from 'react-icons/hi'
import './ViewBusinessFormCopyEmailLinkButtonIcon.css'

toast.configure()
function ViewBusinessFormCopyEmailLinkButtonIcon() {

    const notify = () => {
        navigator.clipboard.writeText(window.localStorage.getItem('email'))
        toast.success('email copied to clipboard',
            {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 4000
            })
    }
    return (
        <div>
            <button type='button' className='view-business-form-email-icon-button' onClick={notify}><HiOutlineMail /></button>
        </div>
    );
}

export default ViewBusinessFormCopyEmailLinkButtonIcon;