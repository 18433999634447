import React from 'react';
import { Link } from 'react-router-dom'
import { SiTiktok} from 'react-icons/si'
import './ViewBusinessFormViewTiktokIconLinkButton.css'

function ViewBusinessFormViewTiktokIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://tiktok.com/' + window.localStorage.getItem('tiktok').substring(1)
             }} ><button type='button' className='view-business-form-tiktok-icon-link-button'><SiTiktok /></button></Link>
        </div>
    );
}
export default ViewBusinessFormViewTiktokIconLinkButton;