import React from 'react';
import { Link } from 'react-router-dom'
import { FaTwitch} from 'react-icons/fa'
import './ViewBusinessFormViewTwitchIconLinkButton.css'

function ViewBusinessFormViewTwitchIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.twitch.tv/' + window.localStorage.getItem('twitch').substring(1)
             }} ><button type='button' className='view-business-form-twitch-icon-link-button'><FaTwitch /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewTwitchIconLinkButton;