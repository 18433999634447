import React from 'react'
import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import './CompanyTable.css'

export const GlobalFilter = ({ filter, setFilter }) => {

    const [value, setValue] = useState(filter)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 1000)
    return (
        <div className='tableSearch'>
            Search table<br></br>{''}
            <input
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            />
        </div>
    )
}

export default GlobalFilter;