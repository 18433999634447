import React from 'react';
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import './ViewBusinessFormViewWhatsappIconLinkButton.css'

function ViewBusinessFormViewWhatsappIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://api.whatsapp.com/send?phone=' + window.localStorage.getItem('phone'),
             }} ><button type='button' className='view-business-form-whatsapp-icon-link-button'><IoLogoWhatsapp /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewWhatsappIconLinkButton;