import React from 'react';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
function ViewBusinessFormCopyAddressButton() {

    const notify = () => {
        var copyText = document.getElementById("view-business-form-address")
        copyText = copyText.innerText.replace('copy global address format','')
        copyText = copyText.trim()
        navigator.clipboard.writeText(copyText)
        toast.success('address copied to clipboard',
            {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 4000
            })
    }
    return (
        <div className='view-business-form-copy-addresss-button-div'>
            <span className="copy-address-msg-span">copy global address format</span><br />
            <button type='button' className='view-business-form-copy-addresss-button' onClick={notify}>
                {window.localStorage.getItem('company_name') === 'smithbook' ? 'full name' : window.localStorage.getItem('owner_full_name')}<br />
                {window.localStorage.getItem('company_name') === 'smithbook' ? 'company name - company type' : window.localStorage.getItem('company_name') + ' - ' + window.localStorage.getItem('service_type')}<br />
                {window.localStorage.getItem('company_name') === 'smithbook' ? 'company address' : window.localStorage.getItem('street_address')}<br />
                {window.localStorage.getItem('company_name') === 'smithbook' ? 'zipcode city state/province' : window.localStorage.getItem('zip_code') + ' ' + window.localStorage.getItem('city') + ' ' + window.localStorage.getItem('state_province')}<br />
                {window.localStorage.getItem('company_name') === 'smithbook' ? 'country' : window.localStorage.getItem('country')}
            </button>
        </div>
    );
}

export default ViewBusinessFormCopyAddressButton;