import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'

function HomeFormLogOutLinkButton() {

  const [error, setError] = useState('')
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError('')

    try {
      window.localStorage.clear()
      await logout()
      history.push('/')
    } catch (e){
      setError('Failed to log out')
      console.log(e)
    }
  }

  return (
    <nav>
      {error}
      <Link to='/'><button className='home-form-logout-link-button' onClick={handleLogout}>Logout: {currentUser.displayName}</button></Link>
    </nav>
  );

}

export default HomeFormLogOutLinkButton