import React from 'react'
import { Link } from 'react-router-dom'

function LandingPageFormSignUpLinkButton() {

  return (
    <nav>
      <Link to='/signup'><button className='landing-page-form-signup-link-button'>Sign up</button></Link>
    </nav>
  );
}
export default LandingPageFormSignUpLinkButton