import React from 'react';
import { Link } from 'react-router-dom'

function ViewSocialCardFormGetCardLinkButton() {
    return (
        <div>
            <Link to='/signup'><button  type='button'className='view-social-card-form-get-card-link-button'>GetMyCard</button></Link>
        </div>
    );
}

export default ViewSocialCardFormGetCardLinkButton;