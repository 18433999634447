import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik'
import ViewSocialCardHeadingLink from './ViewSocialCardHeadingLink'
import ShareSocialCardLinkButton from './ShareSocialCardLinkButton'
import './ViewBusinessForm.css'
import './ViewSocialCard.css'
import firebase from 'firebase'
import { useAuth } from '../.././contexts/AuthContext'
//import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'

import ViewBusinessFormCopyAddressButton from './ViewBusinessFormCopyAddressButton'

import ViewBusinessFormCopyPhoneButtonIcon from './copyiconbutton/ViewBusinessFormCopyPhoneButtonIcon'
import ViewBusinessFormCopyEmailLinkButtonIcon from './copyiconbutton/ViewBusinessFormCopyEmailLinkButtonIcon'
import ViewBusinessFormCopySocialCardLinkButtonIcon from './copyiconbutton/ViewBusinessFormCopySocialCardLinkButtonIcon'

import ViewBusinessFormViewWhatsappIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewWhatsappIconLinkButton'

import ViewBusinessFormViewFaceBookIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewFaceBookIconLinkButton'
import ViewBusinessFormViewInstagramIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewInstagramaIconLinkButton'
import ViewBusinessFormViewTwitterIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewTwitterIconLinkButton'

import ViewBusinessFormViewLinkedInIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewLinkedInIconLinkButton'
import ViewBusinessFormViewSnapchatIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewSnapchatIconLinkButton'
import ViewBusinessFormViewTiktokIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewTiktokIconLinkButton'

import ViewBusinessFormViewTwitchIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewTwitchIconLinkButton'

import ViewBusinessFormViewYouTubeIconLinkButton from './gotoiconlinkbutton/ViewBusinessFormViewYouTubeIconLinkButton'

import ViewBusinessFormCopyBitcoinAddress2ButtonIcon from './copyiconbutton/ViewBusinessFormCopyBitcoinAddress2ButtonIcon'
import ViewBusinessFormCopyEthereumAddressButtonIcon from './copyiconbutton/ViewBusinessFormCopyEthereumAddressButtonIcon'
import ViewBusinessFormCopyXrpAddressButtonIcon from './copyiconbutton/ViewBusinessFormCopyXrpAddressButtonIcon'

import { RiCellphoneFill } from 'react-icons/ri'
//import { MdHttp } from 'react-icons/md'
import { TiSocialTwitter, TiSocialInstagram, TiSocialFacebook } from 'react-icons/ti'
import { IoLogoLinkedin, IoLogoWhatsapp } from 'react-icons/io'
import { FaSnapchatGhost, FaTwitch, FaBitcoin, FaEthereum } from 'react-icons/fa'
import { SiTiktok, SiXrp } from 'react-icons/si'
import { ImYoutube2 } from 'react-icons/im'

import ViewSocialCardFormGetCardLinkButton from './ViewSocialCardFormGetCardLinkButton'
import { useHistory } from 'react-router-dom'
//import { string } from 'yup';

function ViewSocialCard() {

    const { currentUser } = useAuth()

    //console.log(window.localStorage.getItem('bitcoin_address'))
    // {...tableRecord}
    const [loading, setLoading] = useState(false)
    const [record, setRecord] = useState([])

    const initialValues = {
        company_name: '',
        service_type: '',
        date_opened_on: '',
        smith_book_birthday: '',
        owner_full_name: '',
        country: '',
        state_province: '',
        city: '',
        zip_code: '',
        neighborhood_town: '',
        street_address: '',
        phone: '',
        email: '',
        web_site: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        snapchat: '',
        tiktok: '',
        twitch: '',
        bitcoin_address: '',
        ethereum_address: '',
        xrp_address: '',
        youtube: '',
        uid: '',
        records: []
    }

    var path = window.location.href
    //var companyName = path.replace('http://localhost:3000/', '')
    var companyName = path.replace('https://smithbook.org/', '')

    if (companyName === '') {
        companyName = 'yournamehere'
    }

    const history = useHistory()
    useEffect(() => {
        if (currentUser) {
            setRecord(record => ([...record, {
                bitcoin_address: window.localStorage.getItem('bitcoin_address'),
                city: window.localStorage.getItem('city'),
                company_name: window.localStorage.getItem('company_name'),
                country: window.localStorage.getItem('country'),
                date_opened_on: window.localStorage.getItem('date_opened_on'),
                email: window.localStorage.getItem('email'),
                ethereum_address: window.localStorage.getItem('ethereum_address'),
                facebook: window.localStorage.getItem('facebook'),
                instagram: window.localStorage.getItem('instagram'),
                linkedin: window.localStorage.getItem('linkedin'),
                neighborhood_town: window.localStorage.getItem('neighborhood_town'),
                owner_full_name: window.localStorage.getItem('owner_full_name'),
                phone: window.localStorage.getItem('phone'),
                service_type: window.localStorage.getItem('service_type'),
                smith_book_birthday: window.localStorage.getItem('smith_book_birthday'),
                snapchat: window.localStorage.getItem('snapchat'),
                state_province: window.localStorage.getItem('state_province'),
                street_address: window.localStorage.getItem('street_address'),
                tiktok: window.localStorage.getItem('tiktok'),
                twitch: window.localStorage.getItem('twitch'),
                twitter: window.localStorage.getItem('twitter'),
                web_site: window.localStorage.getItem('web_site'),
                xrp_address: window.localStorage.getItem('xrp_address'),
                youtube: window.localStorage.getItem('youtube'),
                zip_code: window.localStorage.getItem('zip_code'),
            }]))
        } else {
            var ref = firebase.database().ref(companyName)
            const interval = ref.on('value', snapshot => {
                setLoading(true)
                var uid = snapshot.val()
                if (uid === null) {
                    clearInterval(interval)
                    history.push('/notfound')
                } else {
                    window.localStorage.clear()
                    window.localStorage.setItem('path', path)
                    var ref = firebase.database().ref('records/-' + uid)
                    ref.on('value', snapshot => {
                        setLoading(true)
                        var card = snapshot.val()
                        try {
                            Object.keys(card)
                            var formatPhone = card.phone
                            formatPhone = formatPhone.replaceAll('-', '')
                            formatPhone = formatPhone.replaceAll('.', '')
                            formatPhone = formatPhone.replace(formatPhone, '1' + formatPhone)
                            setRecord(record => ([...record, {
                                bitcoin_address: card.bitcoin_address,
                                city: card.city,
                                company_name: card.company_name,
                                country: card.country,
                                date_opened_on: card.date_opened_on,
                                email: card.email,
                                ethereum_address: card.ethereum_address,
                                facebook: card.facebook,
                                instagram: card.instagram,
                                linkedin: card.linkedin,
                                neighborhood_town: card.neighborhood_town,
                                owner_full_name: card.owner_first_name + ' ' + card.owner_last_name,
                                phone: formatPhone,
                                service_type: card.service_type,
                                smith_book_birthday: card.smith_book_birthday,
                                snapchat: card.snapchat,
                                state_province: card.state_province,
                                street_address: card.street_address,
                                tiktok: card.tiktok,
                                twitch: card.twitch,
                                twitter: card.twitter,
                                web_site: card.web_site,
                                xrp_address: card.xrp_address,
                                youtube: card.youtube,
                                zip_code: card.zip_code,
                            }]))

                        } catch {
                            return null
                        }
                        return () => {
                            setLoading(false)
                            clearInterval(interval)
                        }
                    })
                }
            })
        }
    }, [companyName, path, currentUser, history])

    return (
        <Formik initialValues={initialValues} >
            {

                formik => {
                    return <Form aria-hidden={loading}>
                        <div className='view-business-form' id='view-business-form'>
                            {record.map(field => (window.localStorage.setItem('owner_full_name', field.owner_full_name)))}
                            {record.map(field => (window.localStorage.setItem('company_name', field.company_name)))}
                            {record.map(field => (window.localStorage.setItem('service_type', field.service_type)))}
                            {record.map(field => (window.localStorage.setItem('street_address', field.street_address)))}
                            {record.map(field => (window.localStorage.setItem('city', field.city)))}
                            {record.map(field => (window.localStorage.setItem('zip_code', field.zip_code)))}
                            {record.map(field => (window.localStorage.setItem('state_province', field.state_province)))}
                            {record.map(field => (window.localStorage.setItem('country', field.country)))}
                            {record.map(field => (window.localStorage.setItem('web_site', field.web_site)))}
                            <ViewSocialCardHeadingLink />
                            <center>
                                <address className='view-business-form-address' id='view-business-form-address'>
                                    <ViewBusinessFormCopyAddressButton />
                                </address>
                            </center>

                            <div className='view-business-form-icon-section-one-div'>
                                {record.map(field => (window.localStorage.setItem('phone', field.phone)))}
                                {window.localStorage.getItem('phone') && window.localStorage.getItem('phone') !== 'undefined' ?
                                    <div className='view-business-form-phone-icon-button-div'>
                                        <div className='view-business-form-phone-icon-button-message-span-div'>
                                            <span className='view-business-form-phone-icon-button-message-span'>copy phone #</span>
                                        </div>
                                        <ViewBusinessFormCopyPhoneButtonIcon />
                                    </div>
                                    : <div className='phone-icon-link-button-disabled-div'>
                                        <div className='phone-icon-link-button-disabled-message-span-div'>
                                            <span className='phone-icon-link-button-disabled-message-span'>no phone</span>
                                        </div><button className='phone-icon-link-button-disabled'><RiCellphoneFill /></button>
                                    </div>}

                                {record.map(field => (window.localStorage.setItem('email', field.email)))}
                                {window.localStorage.getItem('email') ?
                                    <div className='view-business-form-email-icon-button-div'>
                                        <div className='view-business-form-email-icon-button-message-span-div'>
                                            <span className='view-business-form-email-icon-button-message-span'>copy email</span>
                                        </div><ViewBusinessFormCopyEmailLinkButtonIcon />
                                    </div>
                                    : null}

                                <div className='view-business-form-http-link-icon-button-div'>
                                    <div className='view-business-form-http-link-icon-button-message-span-div'>
                                        <span className='view-business-form-http-link-icon-button-message-span'>copy social card link</span>
                                    </div><ViewBusinessFormCopySocialCardLinkButtonIcon />
                                </div>
                            </div>

                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='view-business-form-icon-section-one-div'>
                                {record.map(field => (window.localStorage.setItem('facebook', field.facebook)))}
                                {window.localStorage.getItem('facebook') && window.localStorage.getItem('facebook') !== 'undefined' ?
                                    <div className='view-business-form-facebook-icon-link-button-div'>
                                        <div className='view-business-form-facebook-icon-link-button-message-span-div'>
                                            <span className='view-business-form-facebook-icon-link-button-message-span'>goto facebook</span>
                                        </div> <ViewBusinessFormViewFaceBookIconLinkButton />
                                    </div>
                                    : <div className='facebook-icon-link-button-disabled-div'>
                                        <div className='facebook-icon-link-button-disabled-message-span-div'>
                                            <span className='facebook-icon-link-button-disabled-message-span'>no facebook</span>
                                        </div><TiSocialFacebook className='facebook-icon-link-button-disabled' />
                                    </div>}

                                {record.map(field => (window.localStorage.setItem('instagram', field.instagram)))}
                                {window.localStorage.getItem('instagram') && window.localStorage.getItem('instagram') !== 'undefined' ?
                                    <div className='view-business-form-instagram-icon-link-button-div'>
                                        <div className='view-business-form-instagram-icon-link-button-message-span-div'>
                                            <span className='view-business-form-instagram-icon-link-button-message-span'>goto instagram</span>
                                        </div><ViewBusinessFormViewInstagramIconLinkButton />
                                    </div>
                                    : <div className='instagram-icon-link-button-disabled-div'>
                                        <div className='instagram-icon-link-button-disabled-message-span-div'>
                                            <span className='instagram-icon-link-button-disabled-message-span'>no instagram</span>
                                        </div><TiSocialInstagram className='instagram-icon-link-button-disabled' />
                                    </div>}

                                {record.map(field => (window.localStorage.setItem('twitter', field.twitter)))}
                                {window.localStorage.getItem('twitter') && window.localStorage.getItem('twitter') !== 'undefined' ?
                                    <div className='view-business-form-twitter-icon-link-button-div'>
                                        <div className='view-business-form-twitter-icon-link-button-message-span-div'>
                                            <span className='view-business-form-twitter-icon-link-button-message-span'>goto twitter</span>
                                        </div><ViewBusinessFormViewTwitterIconLinkButton />
                                    </div>
                                    : <div className='twitter-icon-link-button-disabled-div'>
                                        <div className='twitter-icon-link-button-disabled-message-span-div'>
                                            <span className='twitter-icon-link-button-disabled-message-span'>no twitter</span>
                                        </div><TiSocialTwitter className='twitter-icon-link-button-disabled' />
                                    </div>}
                            </div>
                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='view-business-form-icon-section-one-div'>
                                {record.map(field => (window.localStorage.setItem('twitch', field.twitch)))}
                                {window.localStorage.getItem('twitch') && window.localStorage.getItem('twitch') !== 'undefined' ?
                                    <div className='view-business-form-twitch-icon-link-button-div'>
                                        <div className='view-business-form-twitch-icon-link-button-message-span-div'>
                                            <span className='view-business-form-twitch-icon-link-button-message-span'>goto twitch</span>
                                        </div> <ViewBusinessFormViewTwitchIconLinkButton />
                                    </div>
                                    : <div className='twitch-icon-link-button-disabled-div'>
                                        <div className='twitch-icon-link-button-disabled-message-span-div'>
                                            <span className='twitch-icon-link-button-disabled-message-span'>no twitch</span>
                                        </div><FaTwitch className='twitch-icon-link-button-disabled' />
                                    </div>}
                                {record.map(field => (window.localStorage.setItem('snapchat', field.snapchat)))}
                                {window.localStorage.getItem('snapchat') && window.localStorage.getItem('snapchat') !== 'undefined' ?
                                    <div className='view-business-form-snapchat-icon-link-button-div'>
                                        <div className='view-business-form-snapchat-icon-link-button-message-span-div'>
                                            <span className='view-business-form-snapchat-icon-link-button-message-span'>goto snapchat</span>
                                        </div> <ViewBusinessFormViewSnapchatIconLinkButton />
                                    </div>
                                    : <div className='snapchat-icon-link-button-disabled-div'>
                                        <div className='snapchat-icon-link-button-disabled-message-span-div'>
                                            <span className='snapchat-icon-link-button-disabled-message-span'>no snapchat</span>
                                        </div><FaSnapchatGhost className='snapchat-icon-link-button-disabled' />
                                    </div>}
                                {record.map(field => (window.localStorage.setItem('tiktok', field.tiktok)))}
                                {window.localStorage.getItem('tiktok') && window.localStorage.getItem('tiktok') !== 'undefined' ?
                                    <div className='view-business-form-tiktok-icon-link-button-div'>
                                        <div className='view-business-form-tiktok-icon-link-button-message-span-div'>
                                            <span className='view-business-form-tiktok-icon-link-button-message-span'>goto tiktok</span>
                                        </div><ViewBusinessFormViewTiktokIconLinkButton />
                                    </div>
                                    : <div className='tiktok-icon-link-button-disabled-div'>
                                        <div className='tiktok-icon-link-button-disabled-message-span-div'>
                                            <span className='tiktok-icon-link-button-disabled-message-span'>no tiktok</span>
                                        </div><SiTiktok className='tiktok-icon-link-button-disabled' />
                                    </div>}
                            </div>

                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>

                            <div className='view-business-form-icon-section-one-div'>

                                {window.localStorage.getItem('phone') && window.localStorage.getItem('phone') !== 'undefined' ?
                                    <div className='view-business-form-whatsapp-icon-link-button-div'>
                                        <div className='view-business-form-whatsapp-icon-link-button-message-span-div'>
                                            <span className='view-business-form-whatsapp-icon-link-button-message-span'>goto whatsapp biz</span>
                                        </div> <ViewBusinessFormViewWhatsappIconLinkButton />
                                    </div>
                                    : <div className='whatsapp-icon-link-button-disabled-div'>
                                        <div className='whatsapp-icon-link-button-disabled-message-span-div'>
                                            <span className='whatsapp-icon-link-button-disabled-message-span'>no whatsapp</span>
                                        </div><IoLogoWhatsapp className='whatsapp-icon-link-button-disabled' />
                                    </div>}


                                {record.map(field => (window.localStorage.setItem('linkedin', field.linkedin)))}
                                {window.localStorage.getItem('linkedin') && window.localStorage.getItem('linkedin') !== 'undefined' ?
                                    <div className='view-business-form-linkedin-icon-link-button-div'>
                                        <div className='view-business-form-linkedin-icon-link-button-message-span-div'>
                                            <span className='view-business-form-linkedin-icon-link-button-message-span'>goto linkedIn</span>
                                        </div> <ViewBusinessFormViewLinkedInIconLinkButton />
                                    </div>
                                    : <div className='linkedin-icon-link-button-disabled-div'>
                                        <div className='linkedin-icon-link-button-disabled-message-span-div'>
                                            <span className='linkedin-icon-link-button-disabled-message-span'>no linkedin</span>
                                        </div><IoLogoLinkedin className='linkedin-icon-link-button-disabled' />
                                    </div>}
                                {record.map(field => (window.localStorage.setItem('youtube', field.youtube)))}
                                {window.localStorage.getItem('youtube') && window.localStorage.getItem('youtube') !== 'undefined' ?
                                    <div className='view-business-form-youtube-icon-link-button-div'>
                                        <div className='view-business-form-youtube-icon-link-button-message-span-div'>
                                            <span className='view-business-form-youtube-icon-link-button-message-span'>goto youtube</span>
                                        </div><ViewBusinessFormViewYouTubeIconLinkButton />
                                    </div>
                                    : <div className='youtube-icon-link-button-disabled-div'>
                                        <div className='youtube-icon-link-button-disabled-message-span-div'>
                                            <span className='youtube-icon-link-button-disabled-message-span'>no youtube</span>
                                        </div><ImYoutube2 className='youtube-icon-link-button-disabled' />
                                    </div>}
                            </div>

                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>

                            <div className='view-business-form-icon-section-one-div'>
                                {record.map(field => (window.localStorage.setItem('bitcoin_address', field.bitcoin_address)))}
                                {window.localStorage.getItem('bitcoin_address') && window.localStorage.getItem('bitcoin_address') !== 'undefined' ?
                                    <div className='view-business-form-bitcoin2-icon-button-div'>
                                        <div className='view-business-form-bitcoin2-icon-button-message-span-div'>
                                            <span className='view-business-form-bitcoin2-icon-button-message-span'>copy btc address #</span>
                                        </div>
                                        <ViewBusinessFormCopyBitcoinAddress2ButtonIcon />
                                    </div>
                                    : <div className='bitcoin2-icon-link-button-disabled-div'>
                                        <div className='bitcoin2-icon-link-button-disabled-message-span-div'>
                                            <span className='bitcoin2-icon-link-button-disabled-message-span'>no btc address</span>
                                        </div><FaBitcoin className='bitcoin2-icon-link-button-disabled' />
                                    </div>}

                                {record.map(field => (window.localStorage.setItem('ethereum_address', field.ethereum_address)))}
                                {window.localStorage.getItem('ethereum_address') && window.localStorage.getItem('ethereum_address') !== 'undefined' ?
                                    <div className='view-business-form-ethereum-icon-button-div'>
                                        <div className='view-business-form-ethereum-icon-button-message-span-div'>
                                            <span className='view-business-form-ethereum-icon-button-message-span'>copy eth address</span>
                                        </div><ViewBusinessFormCopyEthereumAddressButtonIcon />
                                    </div>
                                    : <div className='ethereum-icon-link-button-disabled-div'>
                                        <div className='ethereum-icon-link-button-disabled-message-span-div'>
                                            <span className='ethereum-icon-link-button-disabled-message-span'>no eth address</span>
                                        </div><FaEthereum className='ethereum-icon-link-button-disabled' />
                                    </div>}
                                {record.map(field => (window.localStorage.setItem('xrp_address', field.xrp_address)))}
                                {window.localStorage.getItem('xrp_address') && window.localStorage.getItem('xrp_address') !== 'undefined' ?
                                    <div className='view-business-form-xrp-icon-button-div'>
                                        <div className='view-business-form-xrp-icon-button-message-span-div'>
                                            <span className='view-business-form-xrp-icon-button-message-span'>copy xrp address</span>
                                        </div><ViewBusinessFormCopyXrpAddressButtonIcon />
                                    </div>
                                    : <div className='xrp-icon-button-disabled-div'>
                                        <div className='xrp-icon-button-disabled-message-span-div'>
                                            <span className='xrp-icon-button-disabled-message-span'>no xrp address</span>
                                        </div><SiXrp className='xrp-icon-button-disabled' />
                                    </div>}
                            </div>
                            <div className='share-social-card-link-button-div'>
                                <ShareSocialCardLinkButton />
                            </div>
                            <div className='view-social-card-form-get-card-link-button-div'>
                                <ViewSocialCardFormGetCardLinkButton />
                            </div>
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default ViewSocialCard;

/*

                                    <div className='view-business-form-whatsapp-icon-link-button-div'>
                                        <div className='view-business-form-whatsapp-icon-link-button-message-span-div'>
                                            <span className='view-business-form-whatsapp-icon-link-button-message-span'>goto twitch</span>
                                        </div> <ViewBusinessFormViewWhatsappIconLinkButton />
                                    </div>
                                    : <div className='whatsapp-icon-link-button-disabled-div'>
                                        <div className='whatsapp-icon-link-button-disabled-message-span-div'>
                                            <span className='whatsapp-icon-link-button-disabled-message-span'>no whatsapp</span>
                                        </div><IoLogoWhatsapp className='whatsapp-icon-link-button-disabled' />
                                    </div>}





                                {window.localStorage.getItem('bitcoin_address') ?
                                    <div className='view-business-form-bitcoin-icon-button-div'>
                                        <div className='view-business-form-bitcoin-icon-button-message-span-div'>
                                            <span className='view-business-form-bitcoin-icon-button-message-span'>copy btc address</span>
                                        </div><ViewBusinessFormCopyBitcoinAddressButtonIcon />
                                    </div>
                                    : <div className='bitcoin-icon-link-button-disabled-div'>
                                        <div className='bitcoin-icon-link-button-disabled-message-span-div'>
                                            <span className='bitcoin-icon-link-button-disabled-message-span'>no address</span>
                                        </div><FaBitcoin className='bitcoin-icon-link-button-disabled' />
                                    </div>}
*/