import React from 'react';
import { Link } from 'react-router-dom'
import { FaSnapchatGhost} from 'react-icons/fa'
import './ViewBusinessFormViewSnapchatIconLinkButton.css'

function ViewBusinessFormViewSnapchatIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.snapchat.com/' + window.localStorage.getItem('snapchat').substring(1)
             }} ><button type='button' className='view-business-form-snapchat-icon-link-button'><FaSnapchatGhost /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewSnapchatIconLinkButton;