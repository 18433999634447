import React from 'react'
import { Link } from 'react-router-dom'

function ViewSocialCardHeadingLink() {
  var heading = ''
  if (window.localStorage.getItem('company_name') === 'smithbook'){
    heading = 'your name here'
  } else {
    heading = window.localStorage.getItem('company_name')
  }

  return (
    <nav>
      <center>
        {
            <Link to={{
              pathname: '/viewwebsite',
              site: 'https://' + window.localStorage.getItem('web_site'),
           }} ><button className='social-card-heading-link-button'>{heading}</button></Link>
        }
      </center>
    </nav>
  );

}

export default ViewSocialCardHeadingLink