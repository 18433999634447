import React from 'react';
import { Link } from 'react-router-dom'

function HomeFormUpdateBusinessLinkButton() {
    return (
        <nav>
        <Link to='/choosebusiness'><button className='home-form-update-business-link-button'>Update Business</button></Link>
      </nav>
    );
}

export default HomeFormUpdateBusinessLinkButton;