import './App.css';
import React from 'react';
//import LandingPage from './components/LandingPage/LandingPageForm'
import SignUp from './components/SignUp/SignUpForm'
import Login from './components/Login/LoginForm'
import ForgotPasswordForm from './components/ForgotPassword/ForgotPasswordForm'
import Home from './components/Home/HomeForm'
import AddBusinessForm from './components/AddBusiness/AddBusinessForm'
import FindBusiness from './components/FindBusiness/FindBusiness'
import ChooseBusinessForm from './components/ChooseBusiness/ChooseBusinessForm'
import UpdateBusinessForm from './components/UpdateBusiness/UpdateBusinessForm'
import AppProtectedRoute from './AppProtectedRoute'
import {AuthProvider} from './contexts/AuthContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import UpdateProfileForm from './components/UpdateProfile/UpdateProfileForm'
import ViewBusinessForm from './components/ViewBusiness/ViewBusinessForm'
import ViewWebSiteForm from './components/ViewBusiness/ViewWebSiteForm'
import ViewSocialCard from './components/ViewBusiness/ViewSocialCard'
import ShareSocialCard from './components/ViewBusiness/ShareSocialCard'
import ErrorPageForm from './components/ErrorPage/ErrorPageForm'
import NotFoundForm from './components/ErrorPage/NotFoundForm'
import LandingPageForm from './components/LandingPage/LandingPageForm';

const App = () => {

  return (
    <AuthProvider>
        <Router>
        <div>
          <Switch>
            <Route path="/" exact component={LandingPageForm} />
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPasswordForm} />
            <AppProtectedRoute path="/home" exact component={Home} />
            <AppProtectedRoute path="/addbusiness" component={AddBusinessForm} />
            <AppProtectedRoute path="/findbusiness" component={FindBusiness} />
            <AppProtectedRoute path="/choosebusiness" component={ChooseBusinessForm} />
            <AppProtectedRoute path="/updatebusiness" component={UpdateBusinessForm} />
            <AppProtectedRoute path="/updateprofile" component={UpdateProfileForm} />
            <AppProtectedRoute path="/viewbusiness" component={ViewBusinessForm} />
            <Route path="/viewwebsite" component={ViewWebSiteForm} />
            <Route path="/socialcard" component={ViewSocialCard} />
            <Route path="/share" component={ShareSocialCard} />
            <Route path="/notfound" component={NotFoundForm} />
            <Route path="/undefined" component={ErrorPageForm} />
            <Route path="/*" component={ViewSocialCard} />
          </Switch>
        </div>
      </Router>
      </AuthProvider>
  )
}
export default App;
