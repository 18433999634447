//import src from '*.avif';
import React from 'react';
import { Link } from 'react-router-dom'
//import { GiShare } from 'react-icons/gi'
import { FaRegCopyright } from 'react-icons/fa'

function ShareSocialCardLinkButton() {

    /* const socialCard = document.querySelector('#view-business-form')
     const dataUri = socialCard.toDataURL('image/jpeg')
     const img = new Image()
     img.src = dataUri*/

    return (
        <div>
            <Link to='/share' >
                <div className='share-social-card-icon-div'><FaRegCopyright className='share-social-card-icon' />
                    <button type='button' className='share-social-card-link-button'>Share Social Card</button>
                </div>
            </Link>
        </div>
    );
}

export default ShareSocialCardLinkButton;