import React, { useState } from 'react'
import './AddBusinessForm.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
//import axios from 'axios'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'
import { useAuth } from '../../contexts/AuthContext'

function AddBusinessForm() {

    var database = firebase.database()
    var ref = database.ref('records')

    const { currentUser } = useAuth()
    var userId = currentUser.uid

    const [loading, setLoading] = useState(false)

    const history = useHistory()

    var date = new Date();
    var formattedDate = format(date, 'yyyy-MM-dd');

    //new Date().toLocaleString()

    const initialValues = {
        company_name: '',
        service_type: '',
        date_opened_on: '',
        smith_book_birthday: '',
        owner_first_name: '',
        owner_last_name: '',
        country: '',
        state_province: '',
        city: '',
        zip_code: '',
        neighborhood_town: '',
        street_address: '',
        phone: '',
        email: '',
        web_site: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        snapchat: '',
        tiktok: '',
        twitch: '',
        youtube: '',
        bitcoin_address: '',
        ethereum_address: '',
        xrp_address: '',
        creator_uid: '',
        verified: '',
        active: ''
    }

    const validationSchema = Yup.object({
        company_name: Yup.string().required('*'),
        service_type: Yup.string().required('*'),
        date_opened_on: Yup.string().required('*'),
        smith_book_birthday: Yup.string(),
        owner_first_name: Yup.string().required('*'),
        owner_last_name: Yup.string().required('*'),
        country: Yup.string().required('*'),
        state_province: Yup.string().required('*'),
        city: Yup.string().required('*'),
        zip_code: Yup.string().required('*'),
        neighborhood_town: Yup.string().required('*'),
        street_address: Yup.string(),
        phone: Yup.string(),
        email: Yup.string().email('Invalid email format').required('*'),
        web_site: Yup.string(),
        facebook: Yup.string(),
        instagram: Yup.string(),
        twitter: Yup.string(),
        linkedin: Yup.string(),
        snapchat: Yup.string(),
        tiktok: Yup.string(),
        twitch: Yup.string(),
        youtube: Yup.string(),
        bitcoin_address: Yup.string(),
        ethereum_address: Yup.string(),
        xrp_address: Yup.string()
    })

    const onSubmit = values => {
        setLoading(true)

        var record = {
            company_name: values.company_name,
            service_type: values.service_type,
            date_opened_on: values.date_opened_on,
            smith_book_birthday: formattedDate,
            owner_first_name: values.owner_first_name,
            owner_last_name: values.owner_last_name,
            country: values.country,
            state_province: values.state_province,
            city: values.city,
            zip_code: values.zip_code,
            neighborhood_town: values.neighborhood_town,
            street_address: values.street_address,
            phone: values.phone,
            email: values.email,
            web_site: values.web_site,
            facebook: values.facebook,
            instagram: values.instagram,
            twitter: values.twitter,
            bitcoin_address: values.bitcoin_address,
            ethereum_address: values.ethereum_address,
            xrp_address: values.xrp_address,
            // record control variables, not displayed in table
            creator_uid: userId, // used to link user to the records they create
            verified: 'false', //used to display if business has been verifed by Smith Book
            active: 'true' //used if business will be shown in the table.
        }

        ref.push(record)
            .then(() => {
                setLoading(false)
                history.push('/findbusiness')
            })
            .catch((err) => {
                setLoading(false)
                console.log('error code: ' + err.code + 'error message: ' + err.message)
            })
            .finally(() => {
                //console.log('business added')
            })

        /*
        //Code to send data to server to be used by Java to commit to PostgreSQL......don't delete
        axios.post('http://localhost:8080/api/v1/addbusiness', values)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
        console.log('Form data: ', values)*/
    }

   // useEffect(() => {
        //console.log('userId from home')
        //console.log(userId)

        //const { userIdFromHome } = props.location
        //console.log('userIdFromHome from home')
        //console.log(userIdFromHome)
    //})

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {

                formik => {
                    return <Form>
                        <div className='add-business-form'>
                            <AppLogoLinkButtonToHome />
                            <div className='add-business-form-control-input-field-div'>
                                <Field 
                                className='add-business-form-control-input-field' 
                                type='text' 
                                id='company_name' 
                                name='company_name' placeholder='Company Name' />
                            </div><div className='error'><ErrorMessage name='company_name' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='service_type' name='service_type' placeholder='Type of service provided' />
                            </div><div className='error'><ErrorMessage name='service_type' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='date_opened_on' name='date_opened_on' placeholder='Date opened on: yyyy-MM-dd' />
                            </div><div className='error'><ErrorMessage name='date_opened_on' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='smith_book_birthday' name='smith_book_birthday' placeholder={'Smith Book Birthday: ' + formattedDate} readOnly />
                            </div><div className='error'><ErrorMessage name='smith_book_birthday' /></div>

                            <div className='add-business-form-control-first-name-input-field-div'>
                                <Field className='add-business-form-control-first-name-input-field' type='text' id='owner_first_name' name='owner_first_name' placeholder='Owner First Name' />
                            </div><div className='error'><ErrorMessage name='owner_first_name' /></div>

                            <div className='add-business-form-control-last-name-input-field-div'>
                                <Field className='add-business-form-control-last-name-input-field' type='text' id='owner_last_name' name='owner_last_name' placeholder='Owner Last Name' />
                            </div><div className='error'><ErrorMessage name='owner_last_name' /></div>

                            <div className='add-business-form-control-country-input-field-div'>
                                <Field className='add-business-form-control-country-input-field' type='text' id='country' name='country' placeholder='Country' />
                            </div><div className='error'><ErrorMessage name='country' /></div>

                            <div className='add-business-form-control-state-province-input-field-div'>
                                <Field className='add-business-form-control-state-province-input-field' type='text' id='state_province' name='state_province' placeholder='State/Province' />
                            </div><div className='error'><ErrorMessage name='state_province' /></div>

                            <div className='add-business-form-control-city-input-field-div'>
                                <Field className='add-business-form-control-city-input-field' type='text' id='city' name='city' placeholder='City' />
                            </div><div className='error'><ErrorMessage name='city' /></div>

                            <div className='add-business-form-control-zip-code-input-field-div'>
                                <Field className='add-business-form-control-zip-code-input-field' type='text' id='zip_code' name='zip_code' placeholder='Zip code' />
                            </div><div className='error'><ErrorMessage name='zip_code' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='neighborhood_town' name='neighborhood_town' placeholder='Neighborhood/Town' />
                            </div><div className='error'><ErrorMessage name='neighborhood_town' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='street_address' name='street_address' placeholder='Street Address' />
                            </div><div className='error'><ErrorMessage name='street_address' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='phone' name='phone' placeholder='Phone' />
                            </div><div className='error'><ErrorMessage name='phone' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='email' name='email' placeholder='Email' />
                            </div><div className='error'><ErrorMessage name='email' /></div>

                            <div className='add-business-form-control-input-field-div'>
                                <Field className='add-business-form-control-input-field' type='text' id='web_site' name='web_site' placeholder='Web Site' />
                            </div><div className='error'><ErrorMessage name='web_site' /></div>

                            <div className='add-business-form-input-control-facebook-field-div'>
                                <Field className='add-business-form-input-control-facebook-field' type='text' id='facebook' name='facebook' placeholder='@facebook' />
                            </div><div className='error'><ErrorMessage name='facebook' /></div>

                            <div className='add-business-form-input-control-instagram-field-div'>
                                <Field className='add-business-form-input-control-instagram-field' type='text' id='instagram' name='instagram' placeholder='@instagram' />
                            </div><div className='error'><ErrorMessage name='sinstagram' /></div>

                            <div className='add-business-form-input-control-twitter-field-div'>
                                <Field className='add-business-form-input-control-twitter-field' type='text' id='twitter' name='twitter' placeholder='@twitter' />
                            </div><div className='error'><ErrorMessage name='twitter' /></div>

                            <div className='add-business-form-input-control-linkedin-field-div'>
                                <Field className='add-business-form-input-control-linkedin-field' type='text' id='linkedin' name='linkedin' placeholder='@linkedin' />
                            </div><div className='error'><ErrorMessage name='linkedin' /></div>

                            <div className='add-business-form-input-control-snapchat-field-div'>
                                <Field className='add-business-form-input-control-snapchat-field' type='text' id='snapchat' name='snapchat' placeholder='@snapchat' />
                            </div><div className='error'><ErrorMessage name='snapchat' /></div>

                            <div className='add-business-form-input-control-tiktok-field-div'>
                                <Field className='add-business-form-input-control-tiktok-field' type='text' id='tiktok' name='tiktok' placeholder='@tiktok' />
                            </div><div className='error'><ErrorMessage name='tiktok' /></div>

                            <div className='add-business-form-control-twitch-input-field-div'>
                                <Field className='add-business-form-control-twitch-input-field' type='text' id='twitch' name='twitch' placeholder='@twitch' />
                            </div><div className='error'><ErrorMessage name='twitch' /></div>

                            <div className='add-business-form-control-youtube-input-field-div'>
                                <Field className='add-business-form-control-youtube-input-field' type='text' id='youtube' name='youtube' placeholder='@youtube' />
                            </div><div className='error'><ErrorMessage name='youtube' /></div>

                            <div className='add-business-form-control-bitcoin-input-field-div'>
                                <Field className='add-business-form-control-bitcoin-input-field' type='text' id='bitcoin_address' name='bitcoin_address' placeholder='Bitcoin Address' />
                            </div><div className='error'><ErrorMessage name='bitcoin_address' /></div>

                            <div className='add-business-form-control-ethereum-input-field-div'>
                                <Field className='add-business-form-control-ethereum-input-field' type='text' id='ethereum_address' name='ethereum_address' placeholder='Ethereum Address' />
                            </div><div className='error'><ErrorMessage name='ethereum_address' /></div>

                            <div className='add-business-form-control-xrp-input-field-div'>
                                <Field className='add-business-form-control-xrp-input-field' type='text' id='xrp_address' name='xrp_address' placeholder='Xrp Address' />
                            </div><div className='error'><ErrorMessage name='xrp_address' /></div>

                            <div className='add-business-form-add-business-button-div'><button className='add-business-form-add-business-button' type="submit" disabled={!formik.isValid || loading}>ADD BUSINESS</button></div>
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default AddBusinessForm;