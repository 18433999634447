import React from 'react';
import { Formik, Form } from 'formik'
import FormikControl from '../.././formik/FormikControl'
import './ErrorPageForm.css'
import ErrorPageFormSignUpLinkButton from './ErrorPageFormSignUpLinkButton'
import ErrorPageFormLoginLinkButton from './ErrorPageFormLoginLinkButton'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react';

function ErrorPageForm() {

    const { currentUser } = useAuth()
    const history = useHistory()

useEffect(() => {
    if(currentUser){
        history.push('/home')
    }
})

    return (
        <Formik>
            {
                //formik => {
                    //return 
                    <Form className='error-page-form'>
                        <AppLogoLinkButtonToHome />
                        <div className='error-page-form-slogan-input-field-div'>
                            <FormikControl
                                className='error-page-form-slogan-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='"404 Error.  Resource not found."'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='You must be logged in to navigate in and out of the'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='SMITHBOOK application.  SOCIAL CARD is a component of'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='SMITHBOOK that can be shared/viewed outside the application.'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='landingPageText'
                                type='text'
                                placeholder='Once you navigate away from the SOCIAL CARD, you have to'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='landingPageText'
                                type='text'
                                placeholder='re-paste the link in the browser unless you are logged in.'
                                readOnly

                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='SOCIAL CARD provides icon links to 8 different social media'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='platforms as well as a direct link to the business web site.'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field2-div'>
                            <FormikControl
                                className='error-page-form-input-field2'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='https://smithbook.org/smithbook'
                                readOnly
                            />
                        </div>
                        <div>
                            <div className='error-page-form-signup-link-button-div'>
                                <ErrorPageFormSignUpLinkButton />
                            </div>
                            <div className='error-page-form-login-link-button-div'>
                                <ErrorPageFormLoginLinkButton />
                            </div>
                        </div>
                    </Form>
               // }
            }
        </Formik>
    );
}

export default ErrorPageForm;