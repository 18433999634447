import React from 'react'
import { Link } from 'react-router-dom'

function UpdateProfileFormHomeLinkButton() {

  return (
    <nav>
      <Link to='/home'><button className='update-profile-form-cancel-update-link-button'>Cancel</button></Link>
    </nav>
  );
}
export default UpdateProfileFormHomeLinkButton