import React from 'react';
import { Link } from 'react-router-dom'

function HomeFormAddBusinessLinkButton() {
    return (
        <nav>
        <Link to='/addbusiness' ><button className='home-form-add-business-link-button'>Add Business</button></Link>
      </nav>
    );
}

export default HomeFormAddBusinessLinkButton;