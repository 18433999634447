import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik'
import './ChooseBusinessForm.css'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import { useAuth } from '../.././contexts/AuthContext'

function ChooseBusinessForm() {

    const { currentUser } = useAuth()

    const [error, setError] = useState()
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState()

    const intitalValues = {
        error: '',
        records: [],
        loading: false
    }

    useEffect(() => {
        var ref = firebase.database().ref('records')
        const interval = ref.on('value', gotData)

        function gotData(data) {
            setLoading(true)

            var allRecords = data.val()
            try {
                var keys = Object.keys(allRecords)
                var recordArray = []
                for (var i = 0; i < keys.length; i++) {
                    var k = keys[i]
                    if (allRecords[k].creator_uid === currentUser.uid) {
                        if (allRecords[k].verified !== 'true') {
                            allRecords[k].service_type = 'verification pending'
                        }
                        allRecords[k].id = k.substr(1, k.length - 1)
                        recordArray.push(allRecords[k])
                    }
                }
                if (recordArray.length !== 0) {
                    setRecords(recordArray)
                } else {
                    setError('You have not added any business or services to update.')
                }
            } catch {
                setError('No records found.')
            }
        }
        return () => {
            setLoading(false)
            clearInterval(interval)
        }
    }, [currentUser.uid])

    return (
        <Formik initialValues={intitalValues}>
            {
                //formik => {
                //return 
                <Form className='choose-business-form' disabled={loading}>
                    <AppLogoLinkButtonToHome />
                    <React.Fragment>
                        <ul className='choose-business-form-ul'>
                            {records.map(record => (
                                <li className='choose-business-form-li' key={record.id}>
                                    <Link to={{
                                        pathname: '/updatebusiness/',
                                        record: record
                                    }}><button className='choose-business-form-li-link-button'>{record.company_name}</button></Link>
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                    <div className='choose-business-form-error-message-div'>
                        <p className='choose-business-form-error-message'>
                            {error}
                        </p>
                    </div>
                </Form>
                // }
            }
        </Formik>
    );
}

export default ChooseBusinessForm;