import React from 'react';
import { Formik, Form, Field } from 'formik'
//import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import ViewSocialCardHeadingLink from './ViewSocialCardHeadingLink'
import ViewBusinessFormFindBusinessLinkButton from './ViewBusinessFormFindBusinessLinkButton'
import ViewBusinessFormShareSocialCardLinkButton from './ViewBusinessFormShareSocialCardLinkButton'
import './ViewBusinessForm.css'
import './ViewSocialCard.css'

import ViewBusinessFormCopyAddressButton from './ViewBusinessFormCopyAddressButton'

import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

function ViewBusinessForm(props) {

    const { record } = props.location

    const history = useHistory()

    if (record) {
        window.localStorage.clear()
        window.localStorage.setItem('company_name', record.company_name)
        window.localStorage.setItem('service_type', record.service_type)
        window.localStorage.setItem('date_opened_on', record.date_opened_on)
        window.localStorage.setItem('smith_book_birthday', record.smith_book_birthday)
        window.localStorage.setItem('owner_full_name', record.owner_first_name + ' ' + record.owner_last_name)
        window.localStorage.setItem('country', record.country)
        window.localStorage.setItem('state_province', record.state_province)
        window.localStorage.setItem('city', record.city)
        window.localStorage.setItem('zip_code', record.zip_code)
        window.localStorage.setItem('neighborhood_town', record.neighborhood_town)
        window.localStorage.setItem('street_address', record.street_address)
        window.localStorage.setItem('phone', record.phone)
        window.localStorage.setItem('email', record.email)
        window.localStorage.setItem('web_site', record.web_site)
        window.localStorage.setItem('facebook', record.facebook)
        window.localStorage.setItem('instagram', record.instagram)
        window.localStorage.setItem('twitter', record.twitter)
        window.localStorage.setItem('linkedin', record.linkedin)
        window.localStorage.setItem('snapchat', record.snapchat)
        window.localStorage.setItem('tiktok', record.tiktok)
        window.localStorage.setItem('twitch', record.twitch)
        window.localStorage.setItem('bitcoin_address', record.bitcoin_address)
        window.localStorage.setItem('ethereum_address', record.ethereum_address)
        window.localStorage.setItem('xrp_address', record.xrp_address)
        window.localStorage.setItem('youtube', record.youtube)
        window.localStorage.setItem('id', record.id.props.children)
        //history.push('/socialcard')
    }


    const initialValues = {
        company_name: '',
        service_type: '',
        date_opened_on: '',
        smith_book_birthday: '',
        owner_full_name: '',
        country: '',
        state_province: '',
        city: '',
        zip_code: '',
        neighborhood_town: '',
        street_address: '',
        phone: '',
        email: '',
        web_site: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        snapchat: '',
        tiktok: '',
        twitch: '',
        bitcoin_address: '',
        youtube: ''
    }


    useEffect(() => {
        if (!record) {
            history.push('/socialcard')
        }
    })

    return (
        <Formik initialValues={initialValues}>
            {

                formik => {
                    return <Form>
                        <div className='view-business-form'>
                            <ViewSocialCardHeadingLink />
                            <center>
                                <address className='view-business-form-address' id='view-business-form-address'>
                                    <ViewBusinessFormCopyAddressButton />
                                </address>
                            </center>

                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>

                            <div className='view-business-form-control-input-field-div'>
                                <Field readOnly className='view-business-form-control-input-field' type='text' name='empty_field' />
                            </div>

                            <div className='view-business-form-control-phone-input-field-div'>
                                <Field readOnly className='view-business-form-control-phone-input-field' type='text' name='phone'
                                placeholder={window.localStorage.getItem('phone') ? 'Phone: ' + window.localStorage.getItem('phone') : 'Phone:'} />
                            </div>

                            <div className='view-business-form-control-email-input-field-div'>
                                <Field readOnly className='view-business-form-control-email-input-field' type='text' name='email'
                                placeholder={'Email: ' + window.localStorage.getItem('email')} />
                            </div>

                            <div className='view-business-form-control-facebook-input-field-div'>
                                <Field readOnly className='view-business-form-control-facebook-input-field' type='text' name='facebook'
                                placeholder={window.localStorage.getItem('facebook') && window.localStorage.getItem('facebook') !== 'undefined' ?
                                'Facebook: ' + window.localStorage.getItem('facebook') : 'Facebook:'} />
                            </div>

                            <div className='view-business-form-control-instagram-input-field-div'>
                                <Field readOnly className='view-business-form-control-instagram-input-field' type='text' name='instagram'
                                placeholder={window.localStorage.getItem('instagram') && window.localStorage.getItem('instagram') !== 'undefined' ?
                                'Instagram: ' + window.localStorage.getItem('instagram') : 'Instagram:'} />
                            </div>

                            <div className='view-business-form-control-twitter-input-field-div'>
                                <Field readOnly className='view-business-form-control-twitter-input-field' type='text' name='twitter'
                                placeholder={window.localStorage.getItem('twitter') && window.localStorage.getItem('twitter') !== 'undefined' ?
                                'Twitter: ' + window.localStorage.getItem('twitter') : 'Twitter:'} />
                            </div>

                            <div className='view-business-form-control-twitch-input-field-div'>
                                <Field readOnly className='view-business-form-control-twitch-input-field' type='text' name='twitch'
                                placeholder={window.localStorage.getItem('twitch') && window.localStorage.getItem('twitch') !== 'undefined' ?
                                'Twitch: ' + window.localStorage.getItem('twitch') : 'Twitch:'} />
                            </div>

                            <div className='view-business-form-control-snapchat-input-field-div'>
                                <Field readOnly className='view-business-form-control-snapchat-input-field' type='text' name='snapchat'
                                placeholder={window.localStorage.getItem('snapchat') && window.localStorage.getItem('snapchat') !== 'undefined' ?
                                'Snapchat: ' + window.localStorage.getItem('snapchat') : 'Snapchat:'}  />
                            </div>

                            <div className='view-business-form-control-tiktok-input-field-div'>
                                <Field readOnly className='view-business-form-control-tiktok-input-field' type='text' name='tiktok'
                                placeholder={window.localStorage.getItem('tiktok') && window.localStorage.getItem('tiktok') !== 'undefined' ?
                                'Tiktok: ' + window.localStorage.getItem('tiktok') : 'Tiktok:'}  />
                            </div>

                            <div className='view-business-form-control-linkedin-input-field-div'>
                                <Field readOnly className='view-business-form-control-linkedin-input-field' type='text' name='linkedin'
                                placeholder={window.localStorage.getItem('linkedin') && window.localStorage.getItem('linkedin') !== 'undefined' ?
                                'Linkedin: ' + window.localStorage.getItem('linkedin') : 'Linkedin:'} />
                            </div>

                            <div className='view-business-form-control-youtube-input-field-div'>
                                <Field readOnly className='view-business-form-control-youtube-input-field' type='text' name='youtube'
                                placeholder={window.localStorage.getItem('youtube') && window.localStorage.getItem('youtube') !== 'undefined' ?
                                'Youtube: ' + window.localStorage.getItem('youtube') : 'Youtube:'} />
                            </div>

                            <div className='view-business-form-find-business-link-button-div'>
                                <ViewBusinessFormFindBusinessLinkButton />
                            </div>
                            <div className='view-business-form-share-business-link-button-div'>
                                <ViewBusinessFormShareSocialCardLinkButton cn={window.localStorage.getItem('company_name')} />
                            </div>
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default ViewBusinessForm;