import React from 'react';
import { Link } from 'react-router-dom'
import { TiSocialTwitter} from 'react-icons/ti'
import './ViewBusinessFormViewTwitterIconLinkButton.css'

function ViewBusinessFormViewTwitterIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://twitter.com/' + window.localStorage.getItem('twitter').substring(1)
             }} ><button type='button' className='view-business-form-twitter-icon-link-button'><TiSocialTwitter /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewTwitterIconLinkButton;