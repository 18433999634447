import React from 'react'
import { Link } from 'react-router-dom'

function LandingPageFormLoginLinkButton() {

  return (
    <nav>
      <Link to='/login'><button className='landing-page-form-login-link-button'>Log in</button></Link>
    </nav>
  );
}

export default LandingPageFormLoginLinkButton