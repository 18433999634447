import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'
import { FaRegCopyright } from 'react-icons/fa'

function AppLogoLinkButtonToHome() {

  const { currentUser } = useAuth()

  return (
    <nav>
      <center>
        {
          currentUser ?
            <Link to='/home'><button className='app-logo-link-button-to-home'>{<div className='app-logo-link-button-to-home-copyright-icon-div'><FaRegCopyright /></div>}SMITHBOOK</button></Link>
            :
            <Link to='/'><button className='app-logo-link-button-to-home'>{<div className='app-logo-link-button-to-home-copyright-icon-div'><FaRegCopyright /></div>}SMITHBOOK</button></Link>
        }
      </center>
    </nav>
  );

}

export default AppLogoLinkButtonToHome