import React from 'react';
import { SiXrp} from 'react-icons/si'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ViewBusinessFormCopyXrpAddressButtonIcon.css'

toast.configure()
function ViewBusinessFormCopyXrpAddressButtonIcon() {

    const notify = () => {
        navigator.clipboard.writeText(window.localStorage.getItem('xrp_address'))
        toast.success('xrp address copied to clipboard',
                        {position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 4000})
    }
    return (
        <div>
            <button
            type='button' 
            className='view-business-form-xrp-icon-button'
            onClick={notify}
            ><SiXrp /></button>
        </div>
    );
}

export default ViewBusinessFormCopyXrpAddressButtonIcon;