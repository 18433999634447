import React, { useMemo, useEffect, useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination, useRowSelect } from 'react-table'
//import { COLUMNS, GROUPED_COLUMNS } from './columns'
import { GROUPED_COLUMNS } from './Columns'
import './CompanyTable.css'
import GlobalFilter from './GlobalFilter'
import ColumnFilter from './ColumnFilter'
//import axios from 'axios'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import firebase from 'firebase'
import { Link } from 'react-router-dom'

//const apiUrl = "http://localhost:8080/api/v1/tester"

export const CompanyTable = () => {

    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = useMemo(() => GROUPED_COLUMNS, [])

    const data = useMemo(() => {
        return records
    }, [records])
    /*
    **important code to access data from postgreSQL**
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(apiUrl)
        .then(res => {
            console.log(res)
            setData(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }, [])*/

    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilter
        }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setGlobalFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        //selectedFlatRows
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 17 },
    }, useGlobalFilter, useFilters, useSortBy, usePagination, useRowSelect/*,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {

                return [
                    {
                        id: 'selection',
                        // Header: ({ getToggleAllRowsSelectedProps }) => (
                        //    <CheckBox {...getToggleAllRowsSelectedProps()} />
                        // ),
                        Cell: ({ row }) => (
                            <CheckBox {...row.getToggleRowSelectedProps()} />
                        )
                    },
                    ...columns
                ]
            })
        }*/)

    const { globalFilter } = state
    //const { pageIndex } = state
    const { pageIndex, pageSize } = state


    useEffect(() => {
        var ref = firebase.database().ref('records')
        const interval = ref.on('value', gotData)

        function gotData(data) {
            setLoading(true)

            var allRecords = data.val()
            try {
                var keys = Object.keys(allRecords)
                var recordArray = []

                for (var i = 0; i < keys.length; i++) {
                    var k = keys[i]
                    allRecords[k].id = k.substr(1, k.length - 1)
                    allRecords[k].id = <Link className='view-business' to={{
                        pathname: '/viewbusiness/',
                        record: allRecords[k]
                    }}>{allRecords[k].id}</Link>

                    if (allRecords[k].verified === 'false') {
                        allRecords[k].service_type = 'verification pending'
                    }
                    var formatPhone = allRecords[k].phone
                    formatPhone = formatPhone.replaceAll('-', '')
                    formatPhone = formatPhone.replaceAll('.', '')
                    formatPhone = formatPhone.replace(formatPhone, '1' + formatPhone)
                    allRecords[k].phone = formatPhone

                    recordArray.push(allRecords[k])
                    //var email = records[k].email
                    //var bday = records[k].date_opened_on
                    //console.log('Email: ' + email)
                    //console.log('Date: ' + bday)
                }
                setRecords(recordArray)
            } catch {
                console.log('no records')
            }
        }

        return () => {
            setLoading(false)
            clearInterval(interval)
        }
    }, [])

    return (
        <><div className='logo-div'><AppLogoLinkButtonToHome /></div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div className='searchByColumn'>To search by column, use text box under each column.</div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? '' : '') : ''}
                                    </span>
                                    <div>
                                        <br></br>{column.canFilter ? column.render('Filter') : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} disabled={loading}>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <span className='select-page'>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </span>
            <span className='select-page'>
                | Go to page: {' '}
                <input type='number' defaultValue={pageIndex + 1} onChange={e => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(pageNumber)
                }} style={{ width: '50px' }} />
            </span>
            <br></br>
            <select className='show-rows' value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {[10, 15, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
            <div>
                <button className='navButton' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                <button className='navButton' onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                <button className='navButton' onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                <button className='navButton' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
            </div>
            <div>
                <h1 className='loading-data' hidden={loading}>...Loading</h1>
            </div>
        </>
    )
}
/* For posting to an end point, I think.  It's JSON data.

            <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedFlatRows: selectedFlatRows.map((row) => row.original),
                        },
                        null,
                        2
                    )}
                </code>
            </pre>
*/