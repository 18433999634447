import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../formik/FormikControl'
import './ForgotPasswordForm.css'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import ForgotPasswordFormLoginLinkButton from './ForgotPasswordFormLoginLinkButton';
import { useAuth } from '../../contexts/AuthContext'

function ForgotPasswordForm() {

    const { resetPassword } = useAuth()

    const [emailError, setEmailError] = useState('')
    const [resetMessage, setResetMessage] = useState('')

    const [loading, setLoading] = useState(false)

    const clearMessages = () => {
      setEmailError('')
      setResetMessage('')
    }

    const modeOfContactOptions = []

    const intitalValues = {
        email: ''
    }

    const validationSchema = Yup.object({
        email: Yup.string().email('@').required('*'),
    })

    const onSubmit = values => {
        //console.log('Form Values:', values)
        clearMessages()
        try {
            setLoading(true)
            resetPassword(values.email)
            .then((userCredential) => {
                // Signed in
                setResetMessage('Reset password email sent to: ' + values.email)
                //...
              })
            .catch((err) => {
                switch (err.code) {
                    case 'auth/user-disbled':
                    case 'auth/user-not-found':
                        return setEmailError(err.message)
                       // break
                    default: return null
                }
            })
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    function handleEnter (e) {
        if(e.code === 'Enter'){
            e.preventDefault()
            document.getElementById('forgot-password-form-change-password-button').click()
        }
      }

      useEffect(() => {
        window.addEventListener('keypress', handleEnter)
          
        return () => window.removeEventListener("keypress", handleEnter)

    }, [])

    return (
        <Formik initialValues={intitalValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {
                formik => {
                    return <Form className='forgot-password-form'>
                        <AppLogoLinkButtonToHome />
                        <div className='forgot-password-form-first-name-input-field-div'>
                            <FormikControl
                                className='forgot-password-form-first-name-input-field'
                                control='input'
                                name='firstName'
                                type='text'
                                placeholder='First Name'
                                disabled
                            />
                        </div>
                        <div className='forgot-password-form-last-name-input-field-div'>
                            <FormikControl
                                className='forgot-password-form-last-name-input-field'
                                control='input'
                                name='lastName'
                                type='text'
                                placeholder='Last Name'
                                disabled
                            />
                        </div>
                        <div className='forgot-password-form-email-input-field-div'>
                            <Field
                                className='forgot-password-form-email-input-field'
                                control='input'
                                name='email'
                                type='email'
                                placeholder='Email'
                            />
                        </div><div className='error'><ErrorMessage name='email' /></div>
                        <div className='forgot-password-form-password-input-field-div'>
                            <FormikControl
                                className='forgot-password-form-password-input-field'
                                control='input'
                                name='password'
                                type='password'
                                placeholder='Password'
                                disabled
                            />
                        </div>
                        <div className='forgot-password-form-confirm-password-input-field-div'>
                            <FormikControl
                                className='forgot-password-form-confirm-password-input-field'
                                control='input'
                                name='confirmPassword'
                                type='password'
                                placeholder='Confirm Password'
                                disabled
                            />
                        </div>
                        <div className='forgot-password-form-mode-of-contact-radio-button-div'>
                            <FormikControl
                                className='forgot-password-form-mode-of-contact-radio-button'
                                control='radio'
                                label='Mode of contact'
                                name='modeOfContact'
                                options={modeOfContactOptions}
                                disabled
                                
                            />
                        </div>
                        <div className='forgot-password-form-phone-input-field-div'>
                            <FormikControl
                                className='forgot-password-form-phone-input-field'
                                control='input'
                                name='phone'
                                type='text'
                                placeholder='Phone number'
                                disabled
                            />
                        </div>
                        <div className='forgot-password-form-change-password-button-div'>
                            <button className='forgot-password-form-change-password-button' id='forgot-password-form-change-password-button' type='submit' disabled={!formik.isValid || loading}>Change pw</button>
                        </div>
                        <div className='forgot-password-form-login-link-button-div'>
                            <ForgotPasswordFormLoginLinkButton />
                        </div>
                        <div className='firebase-errors'>{emailError}</div>
                        <div className='reset-password-message'>{resetMessage}</div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default ForgotPasswordForm;