import React, { useState } from 'react'
import './UpdateBusinessForm.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'

function UpdateBusinessForm(props) {

    const { record } = props.location

    if (record) {
        var database = firebase.database()
        var ref = database.ref('records/-' + record.id)

        window.localStorage.clear()
        window.localStorage.setItem('company_name', record.company_name)
        window.localStorage.setItem('service_type', record.service_type)
        window.localStorage.setItem('date_opened_on', record.date_opened_on)
        window.localStorage.setItem('smith_book_birthday', record.smith_book_birthday)
        window.localStorage.setItem('owner_first_name', record.owner_first_name)
        window.localStorage.setItem('owner_last_name', record.owner_last_name)
        window.localStorage.setItem('country', record.country)
        window.localStorage.setItem('state_province', record.state_province)
        window.localStorage.setItem('city', record.city)
        window.localStorage.setItem('zip_code', record.zip_code)
        window.localStorage.setItem('neighborhood_town', record.neighborhood_town)
        window.localStorage.setItem('street_address', record.street_address)
        window.localStorage.setItem('phone', record.phone)
        window.localStorage.setItem('email', record.email)
        window.localStorage.setItem('web_site', record.web_site)
        window.localStorage.setItem('facebook', record.facebook)
        window.localStorage.setItem('instagram', record.instagram)
        window.localStorage.setItem('twitter', record.twitter)
        window.localStorage.setItem('linkedin', record.linkedin)
        window.localStorage.setItem('snapchat', record.snapchat)
        window.localStorage.setItem('tiktok', record.tiktok)
        window.localStorage.setItem('twitch', record.twitch)
        window.localStorage.setItem('youtube', record.youtube)
        window.localStorage.setItem('bitcoin_address', record.bitcoin_address)
        window.localStorage.setItem('ethereum_address', record.ethereum_address)
        window.localStorage.setItem('xrp_address', record.xrp_address)
    }

    const [loading, setLoading] = useState(false)

    const history = useHistory()

    const initialValues = {
        company_name: '',
        service_type: '',
        date_opened_on: '',
        smith_book_birthday: '',
        owner_first_name: '',
        owner_last_name: '',
        country: '',
        state_province: '',
        city: '',
        zip_code: '',
        neighborhood_town: '',
        street_address: '',
        phone: '',
        email: '',
        web_site: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        snapchat: '',
        tiktok: '',
        twitch: '',
        youtube: '',
        bitcoin_address: '',
        ethereum_address: '',
        xrp_address: '',
        creator_uid: '',
        verified: '',
        active: ''
    }

    const validationSchema = Yup.object({
        company_name: Yup.string(),
        service_type: Yup.string(),
        date_opened_on: Yup.string(),
        smith_book_birthday: Yup.string(),
        owner_first_name: Yup.string(),
        owner_last_name: Yup.string(),
        country: Yup.string(),
        state_province: Yup.string(),
        city: Yup.string(),
        zip_code: Yup.string(),
        neighborhood_town: Yup.string(),
        street_address: Yup.string(),
        phone: Yup.string(),
        email: Yup.string().email('Invalid email format').required('*'),
        web_site: Yup.string(),
        facebook: Yup.string(),
        instagram: Yup.string(),
        twitter: Yup.string(),
        linkedin: Yup.string(),
        snapchat: Yup.string(),
        tiktok: Yup.string(),
        twitch: Yup.string(),
        youtube: Yup.string(),
        bitcoin_address: Yup.string(),
        ethereum_address: Yup.string(),
        xrp_address: Yup.string()
    })

    const onSubmit = values => {
        setLoading(true)

        if (values.company_name) { var company_name = values.company_name } else { company_name = record.company_name }
        if (values.service_type) { var service_type = values.service_type } else { service_type = record.service_type }
        if (values.date_opened_on) { var date_opened_on = values.date_opened_on } else { date_opened_on = record.date_opened_on }
        if (values.smith_book_birthday) { var smith_book_birthday = values.smith_book_birthday } else { smith_book_birthday = record.smith_book_birthday }
        if (values.owner_first_name) { var owner_first_name = values.owner_first_name } else { owner_first_name = record.owner_first_name }
        if (values.owner_last_name) { var owner_last_name = values.owner_last_name } else { owner_last_name = record.owner_last_name }
        if (values.country) { var country = values.country } else { country = record.country }
        if (values.state_province) { var state_province = values.state_province } else { state_province = record.state_province }
        if (values.city) { var city = values.city } else { city = record.city }
        if (values.zip_code) { var zip_code = values.zip_code } else { zip_code = record.zip_code }
        if (values.neighborhood_town) { var neighborhood_town = values.neighborhood_town } else { neighborhood_town = record.neighborhood_town }
        if (values.street_address) { var street_address = values.street_address } else { if (typeof record.street_address === 'undefined') { street_address = '' } else { street_address = record.street_address } }
        if (values.phone) { var phone = values.phone } else { if (typeof record.phone === 'undefined') { phone = '' } else { phone = record.phone } }
        if (values.email) { var email = values.email } else { email = record.email }
        if (values.web_site) { var web_site = values.web_site } else { if (typeof record.web_site === 'undefined') { web_site = '' } else { web_site = record.web_site } }
        if (values.facebook) { var facebook = values.facebook } else { if (typeof record.facebook === 'undefined') { facebook = '' } else { facebook = record.facebook } }
        if (values.instagram) { var instagram = values.instagram } else { if (typeof record.instagram === 'undefined') { instagram = '' } else { instagram = record.instagram } }
        if (values.twitter) { var twitter = values.twitter } else { if (typeof record.twitter === 'undefined') { twitter = '' } else { twitter = record.twitter } }
        if (values.linkedin) { var linkedin = values.linkedin } else { if (typeof record.linkedin === 'undefined') { linkedin = '' } else { linkedin = record.linkedin } }
        if (values.snapchat) { var snapchat = values.snapchat } else { if (typeof record.snapchat === 'undefined') { snapchat = '' } else { snapchat = record.snapchat } }
        if (values.tiktok) { var tiktok = values.tiktok } else { if (typeof record.tiktok === 'undefined') { tiktok = '' } else { tiktok = record.tiktok } }
        if (values.twitch) { var twitch = values.twitch } else { if (typeof record.twitch === 'undefined') { twitch = '' } else { twitch = record.twitch } }
        if (values.youtube) { var youtube = values.youtube } else { if (typeof record.youtube === 'undefined') { youtube = '' } else { youtube = record.youtube } }
        if (values.bitcoin_address) { var bitcoin_address = values.bitcoin_address } else { if (typeof record.bitcoin_address === 'undefined') { bitcoin_address = '' } else { bitcoin_address = record.bitcoin_address } }
        if (values.ethereum_address) { var ethereum_address = values.ethereum_address } else { if (typeof record.ethereum_address === 'undefined') { ethereum_address = '' } else { ethereum_address = record.ethereum_address } }
        if (values.xrp_address) { var xrp_address = values.xrp_address } else { if (typeof record.xrp_address === 'undefined') { xrp_address = '' } else { xrp_address = record.xrp_address } }
        var updates = {
            company_name: company_name,
            service_type: service_type,
            date_opened_on: date_opened_on,
            smith_book_birthday: smith_book_birthday,
            owner_first_name: owner_first_name,
            owner_last_name: owner_last_name,
            country: country,
            state_province: state_province,
            city: city,
            zip_code: zip_code,
            neighborhood_town: neighborhood_town,
            street_address: street_address,
            phone: phone,
            email: email,
            web_site: web_site,
            facebook: facebook,
            instagram: instagram,
            twitter: twitter,
            linkedin: linkedin,
            snapchat: snapchat,
            tiktok: tiktok,
            twitch: twitch,
            youtube: youtube,
            bitcoin_address: bitcoin_address,
            ethereum_address: ethereum_address,
            xrp_address: xrp_address,
            creator_uid: record.creator_uid,
            verified: record.verified,
            active: record.active
        }

        try {
            ref.update(updates, () => {
                try {
                    setLoading(false)
                    history.push('/findbusiness')
                } catch (err) {
                    setLoading(false)
                    console.log(err.code)
                }
            })
        } catch (err) {
            setLoading(false)
            console.log(err.code)
        }


        /*
        //Code to send data to server to be used by Java to commit to PostgreSQL......don't delete
        axios.post('http://localhost:8080/api/v1/addbusiness', values)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
        console.log('Form data: ', values)*/
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {

                formik => {
                    return <Form>
                        <div className='update-business-form'>
                            <AppLogoLinkButtonToHome />
                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='company_name' name='company_name' placeholder={'Business Name - ' + window.localStorage.getItem('company_name')} />
                            </div><div className='error'><ErrorMessage name='company_name' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='service_type' name='service_type' placeholder={'Service Type - ' + window.localStorage.getItem('service_type')} />
                            </div><div className='error'><ErrorMessage name='service_type' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='date_opened_on' name='date_opened_on' placeholder={'Date opened on - ' + window.localStorage.getItem('date_opened_on')} />
                            </div><div className='error'><ErrorMessage name='date_opened_on' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='smith_book_birthday' name='smith_book_birthday' placeholder={'Smith Book Birthday - ' + window.localStorage.getItem('smith_book_birthday')} readOnly />
                            </div><div className='error'><ErrorMessage name='smith_book_birthday' /></div>

                            <div className='update-business-form-control-first-name-input-field-div'>
                                <Field className='update-business-form-control-first-name-input-field' type='text' id='owner_first_name' name='owner_first_name' placeholder={'First Name - ' + window.localStorage.getItem('owner_first_name')} />
                            </div><div className='error'><ErrorMessage name='owner_first_name' /></div>

                            <div className='update-business-form-control-last-name-input-field-div'>
                                <Field className='update-business-form-control-last-name-input-field' type='text' id='owner_last_name' name='owner_last_name' placeholder={'Last Name - ' + window.localStorage.getItem('owner_last_name')} />
                            </div><div className='error'><ErrorMessage name='owner_last_name' /></div>

                            <div className='update-business-form-control-country-input-field-div'>
                                <Field className='update-business-form-control-country-input-field' type='text' id='country' name='country' placeholder={'Country - ' + window.localStorage.getItem('country')} />
                            </div><div className='error'><ErrorMessage name='country' /></div>

                            <div className='update-business-form-control-country-input-field-div'>
                                <Field className='update-business-form-control-country-input-field' type='text' id='state_province' name='state_province' placeholder={'State/Province - ' + window.localStorage.getItem('state_province')} />
                            </div><div className='error'><ErrorMessage name='state_province' /></div>

                            <div className='update-business-form-control-city-input-field-div'>
                                <Field className='update-business-form-control-city-input-field' type='text' id='city' name='city' placeholder={'City - ' + window.localStorage.getItem('city')} />
                            </div><div className='error'><ErrorMessage name='city' /></div>

                            <div className='update-business-form-control-zip-code-input-field-div'>
                                <Field className='update-business-form-control-zip-code-input-field' type='text' id='zip_code' name='zip_code' placeholder={'Zip code - ' + window.localStorage.getItem('zip_code')} />
                            </div><div className='error'><ErrorMessage name='zip_code' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='neighborhood_town' name='neighborhood_town' placeholder={'Neighborhood/Town - ' + window.localStorage.getItem('neighborhood_town')} />
                            </div><div className='error'><ErrorMessage name='neighborhood_town' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='street_address' name='street_address' placeholder={window.localStorage.getItem('street_address') && window.localStorage.getItem('street_address') !== 'undefined' ? 'Street Address - ' + window.localStorage.getItem('street_address') : 'Street Address'} />
                            </div><div className='error'><ErrorMessage name='street_address' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='phone' name='phone' placeholder={window.localStorage.getItem('phone') && window.localStorage.getItem('phone') !== 'undefined' ? 'Phone - ' + window.localStorage.getItem('phone') : 'Phone'} />
                            </div><div className='error'><ErrorMessage name='phone' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='email' name='email' placeholder={'Email - ' + window.localStorage.getItem('email')} />
                            </div><div className='error'><ErrorMessage name='email' /></div>

                            <div className='update-business-form-control-div'>
                                <Field className='update-business-form-control-input-field' type='text' id='web_site' name='web_site' placeholder={window.localStorage.getItem('web_site') && window.localStorage.getItem('web_site') !== 'undefined' ? 'Web site - ' + window.localStorage.getItem('web_site') : 'Web site'} />
                            </div><div className='error'><ErrorMessage name='web_site' /></div>

                            <div className='update-business-form-control-facebook-div'>
                                <Field className='update-business-form-control-facebook-field' type='text' id='facebook' name='facebook' placeholder={window.localStorage.getItem('facebook') && window.localStorage.getItem('facebook') !== 'undefined' ? 'fb: ' + window.localStorage.getItem('facebook') : '@facebook'} />
                            </div><div className='error'><ErrorMessage name='facebook' /></div>

                            <div className='update-business-form-control-instagram-div'>
                                <Field className='update-business-form-control-instagram-field' type='text' id='instagram' name='instagram' placeholder={window.localStorage.getItem('instagram') && window.localStorage.getItem('instagram') !== 'undefined' ? 'ig: ' + window.localStorage.getItem('instagram') : '@instagram'} />
                            </div><div className='error'><ErrorMessage name='instagram' /></div>

                            <div className='update-business-form-control-twitter-div'>
                                <Field className='update-business-form-control-twitter-field' type='text' id='twitter' name='twitter' placeholder={window.localStorage.getItem('twitter') && window.localStorage.getItem('twitter') !== 'undefined' ? 'twr: ' + window.localStorage.getItem('twitter') : '@twitter'} />
                            </div><div className='error'><ErrorMessage name='twitter' /></div>

                            <div className='update-business-form-control-linkedin-div'>
                                <Field className='update-business-form-control-linkedin-field' type='text' id='linkedin' name='linkedin' placeholder={window.localStorage.getItem('linkedin') && window.localStorage.getItem('linkedin') !== 'undefined' ? 'in: ' + window.localStorage.getItem('linkedin') : '@linkedin'} />
                            </div><div className='error'><ErrorMessage name='linkedin' /></div>

                            <div className='update-business-form-control-snapchat-div'>
                                <Field className='update-business-form-control-snapchat-field' type='text' id='snapchat' name='snapchat' placeholder={window.localStorage.getItem('snapchat') && window.localStorage.getItem('snapchat') !== 'undefined' ? 'snap: ' + window.localStorage.getItem('snapchat') : '@snapchat'} />
                            </div><div className='error'><ErrorMessage name='snapchat' /></div>

                            <div className='update-business-form-control-tiktok-div'>
                                <Field className='update-business-form-control-tiktok-field' type='text' id='tiktok' name='tiktok' placeholder={window.localStorage.getItem('tiktok') && window.localStorage.getItem('tiktok') !== 'undefined' ? 'tik: ' + window.localStorage.getItem('tiktok') : '@tiktok'} />
                            </div><div className='error'><ErrorMessage name='tiktok' /></div>


                            <div className='update-business-form-control-twitch-input-field-div'>
                                <Field className='update-business-form-control-twitch-input-field' type='text' id='twitch' name='twitch' placeholder={window.localStorage.getItem('twitch') && window.localStorage.getItem('twitch') !== 'undefined' ? 'twch: ' + window.localStorage.getItem('twitch') : '@twitch'} />
                            </div><div className='error'><ErrorMessage name='twitch' /></div>

                            <div className='update-business-form-control-youtube-input-field-div'>
                                <Field className='update-business-form-control-youtube-input-field' type='text' id='youtube' name='youtube' placeholder={window.localStorage.getItem('youtube') && window.localStorage.getItem('youtube') !== 'undefined' ? 'yt: ' + window.localStorage.getItem('youtube') : '@youtube'} />
                            </div><div className='error'><ErrorMessage name='youtube' /></div>

                            <div className='update-business-form-control-bitcoin-input-field-div'>
                                <Field className='update-business-form-control-bitcoin-input-field' type='text' id='bitcoin_address' name='bitcoin_address' placeholder={window.localStorage.getItem('bitcoin_address') && window.localStorage.getItem('bitcoin_address') !== 'undefined' ? 'btc: ' + window.localStorage.getItem('bitcoin_address') : 'Bitcoin Address'} />
                            </div><div className='error'><ErrorMessage name='bitcoin_address' /></div>


                            <div className='update-business-form-control-ethereum-input-field-div'>
                                <Field className='update-business-form-control-ethereum-input-field' type='text' id='ethereum_address' name='ethereum_address' placeholder={window.localStorage.getItem('ethereum_address') && window.localStorage.getItem('ethereum_address') !== 'undefined' ? 'eth: ' + window.localStorage.getItem('ethereum_address') : 'Ethereum Address'} />
                            </div><div className='error'><ErrorMessage name='ethereum_address' /></div>

                            <div className='update-business-form-control-xrp-input-field-div'>
                                <Field className='update-business-form-control-xrp-input-field' type='text' id='xrp_address' name='xrp_address' placeholder={window.localStorage.getItem('xrp_address') && window.localStorage.getItem('xrp_address') !== 'undefined' ? 'xrp: ' + window.localStorage.getItem('xrp_address') : 'Xrp Address'} />
                            </div><div className='error'><ErrorMessage name='xrp_address' /></div>

                            <div className='update-business-form-update-business-button-div'><button className='update-business-form-update-business-button' type="submit" disabled={!formik.isValid || loading}>UPDATE BUSINESS</button></div>
                            
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default UpdateBusinessForm;