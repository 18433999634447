import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../fire'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    async function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    async function updateDisplayName(fullName) {
        currentUser.updateProfile({
            displayName: fullName,
            //photoURL: "https://example.com/jane-q-user/profile.jpg"
            // }).then(function() {
            // Update successful.
            // }).catch(function(error) {
            // An error happened.
        });
        return currentUser
    }

    function logout() {
        auth.signOut()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            /*if(user){
                console.log('found user')
            } else {
                console.log('no user found')
            }*/
            setCurrentUser(user)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signup,
        login,
        resetPassword,
        logout,
        updateEmail,
        updatePassword,
        updateDisplayName
    }

    return (
        <AuthContext.Provider value={value}>
            { children}
        </AuthContext.Provider>
    );
}
