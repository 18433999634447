//import { ColumnFilter } from './ColumnFilter'

export const COLUMNS = [
    {
        Header: 'I.d.',
        Footer: 'I.d.',
        accessor: 'id'
    },
    {
        Header: 'Company Name',
        Footer: 'Company Name',
        accessor: 'company_name'
    },
    {
        Header: 'Service Type',
        Footer: 'Service Type',
        accessor: 'service_type'
    },
    {
        Header: 'Date Opened On',
        Footer: 'Date Opened On',
        accessor: 'date_opened_on'
    },
    {
        Header: 'Smith Book B-Day',
        Footer: 'Smith Book B-Day',
        accessor: 'smith_book_birthday'
    },
    {
        Header: 'Owner First Name',
        Footer: 'Owner First Name',
        accessor: 'owner_first_name'
    },
    {
        Header: 'Owner Last Name',
        Footer: 'Owner Last Name',
        accessor: 'owner_last_name'
    },
    {
        Header: 'Country',
        Footer: 'Country',
        accessor: 'country'
    },
    {
        Header: 'State',
        Footer: 'State',
        accessor: 'state_province'
    },
    {
        Header: 'City',
        Footer: 'City',
        accessor: 'city'
    },
    {
        Header: 'Zip Code',
        Footer: 'Zip Code',
        accessor: 'zip_code'
    },
    {
        Header: 'Neighborhood',
        Footer: 'Neighborhood',
        accessor: 'neighborhood_town'
    },
    {
        Header: 'Street Address',
        Footer: 'Street Address',
        accessor: 'street_address'
    },
    {
        Header: 'Phone',
        Footer: 'Phone',
        accessor: 'phone'
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Web Site',
        Footer: 'Web Site',
        accessor: 'web_site'
    },
    {
        Header: 'Facebook',
        Footer: 'Facebook',
        accessor: 'facebook'
    },
    {
        Header: 'Instagram',
        Footer: 'Instagram',
        accessor: 'instagram'
    },
    {
        Header: 'LinkedIn',
        Footer: 'LinkedIn',
        accessor: 'linkedin'
    },
    {
        Header: 'Snapchat',
        Footer: 'Snapchat',
        accessor: 'snapchat'
    },
    {
        Header: 'Twitter',
        Footer: 'Twitter',
        accessor: 'twitter'
    },
    {
        Header: 'TikTok',
        Footer: 'TikTok',
        accessor: 'tiktok'
    },
    {
        Header: 'Twitch',
        Footer: 'Twitch',
        accessor: 'twitch'
    },
    {
        Header: 'YouTube',
        Footer: 'YouTube',
        accessor: 'youtube'
    },
    {
        Header: 'Bitcoin Address',
        Footer: 'Bitcoin Address',
        accessor: 'bitcoin_address'
    },
    {
        Header: 'Ethereum Address',
        Footer: 'Ethereum Address',
        accessor: 'ethereum_address'
    },
    {
        Header: 'Xrp Address',
        Footer: 'Xrp Address',
        accessor: 'xrp_address'
    },
]

export const GROUPED_COLUMNS = [
    {
        Header: 'C o m p a n y',
        Footer: 'C o m p a n y',
        columns: [
            {
                Header: 'I.d.',
                Footer: 'I.d.',
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: 'Name',
                Footer: 'Name',
                accessor: 'company_name'
            },
            {
                Header: 'Service Type',
                Footer: 'Service Type',
                accessor: 'service_type'
            },
        ]
    },
    {
        Header: 'O w n e r',
        Footer: 'O w n e r',
        columns: [
            {
                Header: 'First',
                Footer: 'First',
                accessor: 'owner_first_name'
            },
            {
                Header: 'Last',
                Footer: 'Last',
                accessor: 'owner_last_name'
            },
        ]
    },
    {
        Header: 'L o c a t i o n',
        Footer: 'L o c a t i o n',
        columns: [
            {
                Header: 'State',
                Footer: 'State',
                accessor: 'state_province'
            },
            {
                Header: 'City',
                Footer: 'City',
                accessor: 'city'
            },
            {
                Header: 'Zip',
                Footer: 'Zip',
                accessor: 'zip_code'
            },
            {
                Header: 'Neighborhd',
                Footer: 'Neighborhd',
                accessor: 'neighborhood_town'
            },
            {
                Header: 'Street Addr.',
                Footer: 'Street Addr.',
                accessor: 'street_address'
            },
        ]
    },
    {
        Header: 'C o n t a c t',
        Footer: 'C o n t a c t',
        columns: [
            {
                Header: 'Phone',
                Footer: 'Phone',
                accessor: 'phone'
            },
            {
                Header: 'Email',
                Footer: 'Email',
                accessor: 'email'
            },
        ]
    },
        {
        Header: 'S o c i a l s',
        Footer: 'S o c i a l s',
        columns: [
            {
                Header: 'Web Site',
                Footer: 'Web Site',
                accessor: 'web_site'
            },
            {
                Header: 'Facebook',
                Footer: 'Facebook',
                accessor: 'facebook'
            },
            {
                Header: 'Instagram',
                Footer: 'Instagram',
                accessor: 'instagram'
            },
            {
                Header: 'LinkedIn',
                Footer: 'LinkedIn',
                accessor: 'linkedin'
            },
            {
                Header: 'Snapchat',
                Footer: 'Snapchat',
                accessor: 'snapchat'
            },
            {
                Header: 'Twitter',
                Footer: 'Twitter',
                accessor: 'twitter'
            },
            {
                Header: 'TikTok',
                Footer: 'TikTok',
                accessor: 'tiktok'
            },
            {
                Header: 'Twitch',
                Footer: 'Twitch',
                accessor: 'twitch'
            },
            {
                Header: 'YouTube',
                Footer: 'YouTube',
                accessor: 'youtube'
            },
        ]
    },
    {
        Header: 'D o n a t i o n',
        Footer: 'D o n a t i o n',
        columns: [
            {
                Header: 'Bitcoin',
                Footer: 'Bitcoin',
                accessor: 'bitcoin_address'
            },
            {
                Header: 'Ethereum',
                Footer: 'Ethereum',
                accessor: 'ethereum_address'
            },
            {
                Header: 'Xrp',
                Footer: 'Xrp',
                accessor: 'xrp_address'
            },
        ]
    }
]

export const MINI_GROUPED_COLUMNS = [
    {
        Header: 'C o m p a n y',
        Footer: 'C o m p a n y',
        columns: [
            {
                Header: 'I.d.',
                Footer: 'I.d.',
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: 'Company Name',
                Footer: 'Company Name',
                accessor: 'company_name'
            },
            {
                Header: 'Service Type',
                Footer: 'Service Type',
                accessor: 'service_type'
            },
        ]
    },
    {
        Header: 'L o c a t i o n',
        Footer: 'L o c a t i o n',
        columns: [
            {
                Header: 'City',
                Footer: 'City',
                accessor: 'city'
            },
            {
                Header: 'Neighborhood/Town',
                Footer: 'Neighborhood/Town',
                accessor: 'neighborhood_town'
            },
        ]
    },   
]