import React from 'react'
import { CompanyTable } from './CompanyTable'
import { CompanyTableSmall } from './CompanyTableSmall'

function FindBusiness() {

    const matchResult = window.matchMedia('(max-width: 575px)')
    if (matchResult.matches) {
    return (
        <CompanyTableSmall />
    );
    } else {
        return (
            <CompanyTable />
        );
    }
}

export default FindBusiness;