import React from 'react'
import { Link } from 'react-router-dom'

function LoginFormForgotPasswordLink() {

  return (
    <nav>
      <Link to='/forgotpassword' className='login-form-forgot-password-link'>Forgot password?</Link>
    </nav>
  );

}

export default LoginFormForgotPasswordLink