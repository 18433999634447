import React from 'react'
import { Link } from 'react-router-dom'

function HomeFormUpdateProfileLinkButton() {

  return (
    <nav>
      <Link to='/updateprofile'><button className='home-form-update-profile-link-button'>Update profile</button></Link>
    </nav>
  );

}

export default HomeFormUpdateProfileLinkButton