import React from 'react';
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import './ErrorPageForm.css'
import ErrorPageFormSignUpLinkButton from './ErrorPageFormSignUpLinkButton'
import ErrorPageFormLoginLinkButton from './ErrorPageFormLoginLinkButton'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
/*import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react';*/

function NotFoundForm() {

    /*const { currentUser } = useAuth()
    const history = useHistory()

useEffect(() => {
    if(currentUser){
        history.push('/home')
    }
})*/

    return (
        <Formik>
            {
                //formik => {
                    //return 
                    <Form className='error-page-form'>
                        <AppLogoLinkButtonToHome />
                        <div className='error-page-form-slogan-input-field-div'>
                            <FormikControl
                                className='error-page-form-slogan-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='"404 Error.  Resource not found."'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder=''
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='Check that you have not mispelled the company name in the url.'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-example-input-field-div'>
                            <FormikControl
                                className='error-page-form-example-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder='Examples:'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-example-input-field-div'>
                            <FormikControl
                                className='error-page-form-example-input-field'
                                control='input'
                                name='landingPageText'
                                type='text'
                                placeholder='https://smithbook.org/smithbook'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-example-input-field-div'>
                            <FormikControl
                                className='error-page-form-example-input-field'
                                control='input'
                                name='landingPageText'
                                type='text'
                                placeholder='https://smithbook.org/hudsontaxservices'
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder=''
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field-div'>
                            <FormikControl
                                className='error-page-form-input-field'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder=''
                                readOnly
                            />
                        </div>
                        <div className='error-page-form-input-field2-div'>
                            <FormikControl
                                className='error-page-form-input-field2'
                                control='input'
                                name='errorPageText'
                                type='text'
                                placeholder=''
                                readOnly
                            />
                        </div>
                        <div>
                            <div className='error-page-form-signup-link-button-div'>
                                <ErrorPageFormSignUpLinkButton />
                            </div>
                            <div className='error-page-form-login-link-button-div'>
                                <ErrorPageFormLoginLinkButton />
                            </div>
                        </div>
                    </Form>
               // }
            }
        </Formik>
    );
}

export default NotFoundForm;