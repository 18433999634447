import React from 'react';
import { Link } from 'react-router-dom'
import { IoLogoLinkedin} from 'react-icons/io'
import './ViewBusinessFormViewLinkedInIconLinkButton.css'

function ViewBusinessFormViewLinkedInIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.linkedin.com/in/' + window.localStorage.getItem('linkedin').substring(1)
             }} ><button type='button' className='view-business-form-linkedin-icon-link-button'><IoLogoLinkedin /></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewLinkedInIconLinkButton;