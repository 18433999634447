import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../.././formik/FormikControl'
import { useAuth } from '../.././contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import './SignUpForm.css'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import SignUpFormLoginLinkButton from './SignUpFormLoginLinkButton';

function SignUpForm() {

    const { signup, currentUser } = useAuth()

    const [name, setName] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [loading, setLoading] = useState(false)

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    const history = useHistory()

    const modeOfContactOptions = []

    const intitalValues = {
        fullName: '',
        email: '',
        password: '',
        confirmPassword: ''
    }

    const ne = String.fromCharCode(8800)
    const validationSchema = Yup.object({
        fullName: Yup.string().required('*'),
        email: Yup.string().email('@').required('*'),
        password: Yup.string().required('*'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), ''], ne).required('*')
    })

    const onSubmit = values => {
        console.log()
        clearErrors()
        setLoading(true)
        signup(values.email, values.password)
            .then((userCredential) => {
                // Signed in
                setLoading(false)
            })
            .catch((err) => {
                console.log('error: ' + err.code + 'error message: ' + err.message)
                setLoading(false)
                switch (err.code) {
                    case 'auth/email-already-in-use':
                    case 'auth/argument-error':
                        return setEmailError(err.message)
                    // break
                    case 'auth/weak-password':
                        return setPasswordError(err.message)
                    // break
                    default: return null
                }
            })
            .finally(() => {
                console.log('signup complete')
                setName(values.fullName)
            })
    }

    function handleEnter (e) {
        if(e.code === 'Enter'){
            e.preventDefault()
            document.getElementById('signup-form-signup-button').click()
        }
      }

    useEffect(() => {
        if (currentUser) {
            console.log('User now logged in.')
            history.push({
                pathname: '/home',
                name: name
            })
        }

        window.addEventListener('keypress', handleEnter)

        return () => window.removeEventListener("keypress", handleEnter)

    }, [currentUser, history, name])

    return (
        <Formik initialValues={intitalValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {
                formik => {
                    return <Form className='signup-form'>
                        <AppLogoLinkButtonToHome />
                        <div className='signup-form-first-name-input-field-div'>
                            <Field
                                className='signup-form-first-name-input-field'
                                control='input'
                                name='firstName'
                                type='text'
                                placeholder='First Name'
                                disabled />
                        </div><div className='error'><ErrorMessage name='firstName' /></div>
                        <div className='signup-form-full-name-input-field-div'>
                            <Field
                                className='signup-form-full-name-input-field'
                                control='input'
                                name='fullName'
                                type='text'
                                placeholder='Full Name (eg. Craig Smith)' />
                        </div><div className='error'><ErrorMessage name='fullName' /></div>
                        <div className='signup-form-email-input-field-div'>
                            <Field
                                className='signup-form-email-input-field'
                                control='input'
                                name='email'
                                type='email'
                                placeholder='Email' />
                        </div><div className='error'><ErrorMessage name='email' /></div>
                        <div className='signup-form-password-input-field-div'>
                            <Field
                                className='signup-form-password-input-field'
                                control='input'
                                name='password'
                                type='password'
                                placeholder='Password' />
                        </div><div className='error'><ErrorMessage name='password' /></div>
                        <div className='signup-form-confirm-password-input-field-div'>
                            <Field
                                className='signup-form-confirm-password-input-field'
                                control='input'
                                name='confirmPassword'
                                type='password'
                                placeholder='Confirm Password' />
                        </div><div className='error'><ErrorMessage name='confirmPassword' /></div>
                        <div className='signup-form-mode-of-contact-radio-button-div'>
                            <FormikControl
                                className='signup-form-mode-of-contact-radio-button'
                                control='radio'
                                label='Mode of contact'
                                name='modeOfContact'
                                options={modeOfContactOptions}
                                disabled />
                        </div>
                        <div className='signup-form-phone-input-field-div'>
                            <Field
                                className='signup-form-phone-input-field'
                                control='input'
                                name='phone'
                                type='text'
                                placeholder='Phone number'
                                disabled
                            />
                        </div>
                        <div className='signup-form-signup-button-div'>
                            <button className='signup-form-signup-button' id='signup-form-signup-button' type='submit' disabled={!formik.isValid || loading}>Sign up</button>
                        </div>
                        <div className='signup-form-login-link-button-div'>
                            <SignUpFormLoginLinkButton />
                        </div>
                        <div className='firebase-errors'>{emailError}</div>
                        <div className='firebase-errors'>{passwordError}</div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default SignUpForm;