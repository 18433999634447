import React from 'react';
import { Formik } from 'formik'

function ViewWebSiteForm(props) {
    const { site } = props.location
    const initialValues = {
        viewSite: site
    }

    return (
        <Formik initialValues={initialValues} >
            {window.location.href = initialValues.viewSite}
        </Formik>
    );
}

export default ViewWebSiteForm;