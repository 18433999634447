import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../formik/FormikControl'
import './UpdateProfileForm.css'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import UpdateProfileFormHomeLinkButton from './UpdateProfileFormHomeLinkButton';
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'


function UpdateProfileForm() {

    const { currentUser, updateEmail, updatePassword, updateDisplayName } = useAuth()

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [loading, setLoading] = useState(false)

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    const history = useHistory()

    const modeOfContactOptions = []

    const intitalValues = {
        fullName: '',
        email: '',
        password: '',
        confirmPassword: ''
    }

    const ne = String.fromCharCode(8800)
    const validationSchema = Yup.object({
        fullName: Yup.string(),
        email: Yup.string().email('@').required('* Email field is required to submit changes.'),
        password: Yup.string(),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), ''], ne)
    })

    const onSubmit = values => {
        clearErrors()
        setLoading(true)
        const promises = []

        if (values.fullName) {
            promises.push(updateDisplayName(values.fullName))
        }

        if (values.email !== currentUser.email) {
            promises.push(updateEmail(values.email))
        }

        if (values.password) {
            promises.push(updatePassword(values.password))
        }

        Promise
            .all(promises)
            .then(() => {
                setLoading(false)
                history.push('/home')
            })
            .catch((err) => {
                console.log('error code: ' + err.code + 'error message: ' + err.message)
                setLoading(false)
                switch (err.code) {
                    case 'auth/argument-error':
                    case 'auth/requires-recent-login':
                    case 'auth/user-token-expired':
                        return setEmailError(err.message)
                    // break
                    case 'auth/weak-password':
                        return setPasswordError(err.message)
                    default: return null
                }
            })
            .finally(() => {
                
            })
}

return (
    <Formik initialValues={intitalValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {
            formik => {
                return <Form className='update-profile-form'>
                    <AppLogoLinkButtonToHome />
                    <div className='update-profile-form-first-name-input-div'>
                        <Field
                            className='update-profile-form-first-name-input'
                            control='input'
                            name='firstName'
                            type='text'
                            placeholder={'First Name: ' + currentUser.displayName} 
                            disabled />
                    </div><div className='error'><ErrorMessage name='firstName' /></div>
                    <div className='update-profile-form-full-name-input-div'>
                        <Field
                            className='update-profile-form-full-name-input'
                            control='input'
                            name='fullName'
                            type='text'
                            placeholder={'Full Name: ' + currentUser.displayName} />
                    </div><div className='error'><ErrorMessage name='fullName' /></div>
                    <div className='update-profile-form-email-input-div'>
                        <Field
                            className='update-profile-form-email-input'
                            control='input'
                            name='email'
                            type='email'
                            placeholder={'Email: ' + currentUser.email} />
                    </div><div className='error'><ErrorMessage name='email' /></div>
                    <div className='update-profile-form-password-input-div'>
                        <Field
                            className='update-profile-form-password-input'
                            control='input'
                            name='password'
                            type='password'
                            placeholder='Password: Leave blank to keep the same' />
                    </div><div className='error'><ErrorMessage name='password' /></div>
                    <div className='update-profile-form-confirm-password-input-div'>
                        <Field
                            className='update-profile-form-confirm-password-input'
                            control='input'
                            name='confirmPassword'
                            type='password'
                            placeholder='Confirm Password' />
                    </div><div className='error'><ErrorMessage name='confirmPassword' /></div>
                    <div className='update-profile-form-mode-of-contact-div'>
                        <FormikControl
                            className='update-profile-form-mode-of-contact'
                            control='radio'
                            label='Mode of contact'
                            name='modeOfContact'
                            options={modeOfContactOptions}
                            disabled />
                    </div>
                    <div className='update-profile-form-phone-input-div'>
                        <Field
                            className='update-profile-form-phone-input'
                            control='input'
                            name='phone'
                            type='text'
                            placeholder='Phone number'
                            disabled
                        />
                    </div>
                    <div className='update-profile-form-update-button-div'>
                        <button className='update-profile-form-update-button' type='submit' disabled={!formik.isValid || loading}>Update</button>
                    </div>
                    <div className='update-profile-form-cancel-update-link-button-div'>
                        <UpdateProfileFormHomeLinkButton />
                    </div>
                    <div className='firebase-errors'><ErrorMessage name='email' />{emailError}</div>
                    <div className='firebase-errors'>{passwordError}</div>
                </Form>
            }
        }
    </Formik>
);
}

export default UpdateProfileForm;