import React from 'react';
import { Link } from 'react-router-dom'

function ViewBusinessFormShareSocialCardLinkButton({cn}) {

    cn = cn.replaceAll(' ', '')
    return (
        <div>
            <Link to={'/' + cn} ><button  type='button'className='view-business-form-share-business-link-button'>Share Business</button></Link>
        </div>
    );
}

export default ViewBusinessFormShareSocialCardLinkButton;