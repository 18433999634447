import React from 'react'
import { Link } from 'react-router-dom'

function LoginFormSignUpLinkButton() {

  return (
    <nav>
      <Link to='/signup'><button className='login-form-signup-link-button'>Sign up</button></Link>
    </nav>
  );
}
export default LoginFormSignUpLinkButton