import React from 'react';
import { Link } from 'react-router-dom'

function ViewBusinessFormFindBusinessLinkButton() {
    return (
        <div>
            <Link to='/findbusiness'><button  type='button'className='view-business-form-find-business-link-button'>Find Business</button></Link>
        </div>
    );
}

export default ViewBusinessFormFindBusinessLinkButton;