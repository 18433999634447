import React from 'react';
import { FaBitcoin} from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ViewBusinessFormCopyBitcoinAddress2ButtonIcon.css'

toast.configure()
function ViewBusinessFormCopyBitcoinAddress2ButtonIcon() {

    const notify = () => {
        navigator.clipboard.writeText(window.localStorage.getItem('bitcoin_address'))
        toast.success('bitcoin address to clipboard',
                        {position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 4000})
    }
    return (
        <div>
            <button type='button' className='view-business-form-bitcoin2-icon-button'
            onClick={notify}
            ><FaBitcoin /></button>
        </div>
    );
}

export default ViewBusinessFormCopyBitcoinAddress2ButtonIcon;