import React, { useMemo, useEffect, useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination, useRowSelect } from 'react-table'
//import { COLUMNS, GROUPED_COLUMNS } from './columns'
import { MINI_GROUPED_COLUMNS } from './Columns'
import './CompanyTable.css'
import GlobalFilter from './GlobalFilter'
import ColumnFilter from './ColumnFilter'
//import axios from 'axios'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome';
import firebase from 'firebase'
import { Link } from 'react-router-dom'

//const apiUrl = "http://localhost:8080/api/v1/tester"

export const CompanyTableSmall = () => {

    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = useMemo(() => MINI_GROUPED_COLUMNS, [])

    const data = useMemo(() => {
        return records
    }, [records])
    /*
    **important code to access data from postgreSQL**
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(apiUrl)
        .then(res => {
            console.log(res)
            setData(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }, [])*/

    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilter
        }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setGlobalFilter,
        //selectedFlatRows
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 17 },
    }, useGlobalFilter, useFilters, useSortBy, usePagination, useRowSelect/*,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {

                return [
                    {
                        id: 'selection',
                        // Header: ({ getToggleAllRowsSelectedProps }) => (
                        //    <CheckBox {...getToggleAllRowsSelectedProps()} />
                        // ),
                        Cell: ({ row }) => (
                            <CheckBox {...row.getToggleRowSelectedProps()} />
                        )
                    },
                    ...columns
                ]
            })
        }*/)

    const { globalFilter } = state

    useEffect(() => {
        var ref = firebase.database().ref('records')
        const interval = ref.on('value', gotData)

        function gotData(data) {
            setLoading(true)

            var allRecords = data.val()
            try {
                var keys = Object.keys(allRecords)
                var recordArray = []

                for (var i = 0; i < keys.length; i++) {
                    var k = keys[i]
                    allRecords[k].id = k.substr(1, k.length - 1)
                    allRecords[k].id = <Link className='view-business' to={{
                        pathname: '/viewbusiness/',
                        record: allRecords[k]
                    }}>{allRecords[k].id}</Link>

                    if (allRecords[k].verified === 'false') {
                        allRecords[k].service_type = 'verification pending'
                    }
                    recordArray.push(allRecords[k])
                    //var email = records[k].email
                    //var bday = records[k].date_opened_on
                    //console.log('Email: ' + email)
                    //console.log('Date: ' + bday)
                }
                setRecords(recordArray)
            } catch {
                console.log('no records')
            }
        }

        return () => {
            setLoading(false)
            clearInterval(interval)
        }
    }, [])

    return (

        <><AppLogoLinkButtonToHome />
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div className='searchByColumn'>Click on Company ID to view all company info.</div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? '' : '') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} disabled={loading}>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <br></br>
            <div>
                <h1 className='loading-data' hidden={loading}>...Loading</h1>
            </div>
        </>
    )
}
/* For posting to an end point, I think.  It's JSON data.

            <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedFlatRows: selectedFlatRows.map((row) => row.original),
                        },
                        null,
                        2
                    )}
                </code>
            </pre>
*/