import React from 'react';
import { Formik, Form, Field } from 'formik'
import ViewSocialCardHeadingLink from './ViewSocialCardHeadingLink'
//import ViewSocialCardFooterLink from './ViewSocialCardFooterLink'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import './ViewBusinessForm.css'
import './ViewSocialCard.css'
import './ShareSocialCard.css'

/*
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import ShareSocialCardLinkButton from './ShareSocialCardLinkButton'
import { RiCellphoneFill } from 'react-icons/ri'
import { MdHttp } from 'react-icons/md'
import { TiSocialTwitter, TiSocialInstagram, TiSocialFacebook } from 'react-icons/ti'
import { IoLogoLinkedin } from 'react-icons/io'
import { FaSnapchatGhost, FaTwitch, FaBitcoin } from 'react-icons/fa'
import { SiTiktok } from 'react-icons/si'
import { ImYoutube2 } from 'react-icons/im'
*/

import {
    EmailShareButton,
    FacebookShareButton,
    //FacebookMessengerShareButton,
    //HatenaShareButton,
    InstapaperShareButton,
    //LineShareButton,
    LinkedinShareButton,
    //LivejournalShareButton,
    //MailruShareButton,
    //OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    //TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    //VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share"

/*
import {
    FacebookShareCount,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from "react-share"
*/

import {
    EmailIcon,
    FacebookIcon,
    //FacebookMessengerIcon,
    //HatenaIcon,
    InstapaperIcon,
    //LineIcon,
    LinkedinIcon,
    //LivejournalIcon,
    //MailruIcon,
    //OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    //TumblrIcon,
    TwitterIcon,
    ViberIcon,
    //VKIcon,
    //WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share"

function ShareSocialCard() {

    const initialValues = {
        company_name: '',
        service_type: '',
        date_opened_on: '',
        smith_book_birthday: '',
        owner_full_name: '',
        country: '',
        state_province: '',
        city: '',
        zip_code: '',
        neighborhood_town: '',
        street_address: '',
        phone: '',
        email: '',
        web_site: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        snapchat: '',
        tiktok: '',
        twitch: '',
        bitcoin_address: '',
        youtube: '',
        uid: '',
    }

    return (
        <Formik initialValues={initialValues}>
            {

                formik => {
                    return <Form >
                        <div className='share-social-card-form-div'>
                        {window.localStorage.getItem('company_name')  === 'smithbook' ? <AppLogoLinkButtonToHome /> : <ViewSocialCardHeadingLink />}
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-button-section-div'>
                                <div className='share-social-card-form-button-div'>
                                    <EmailShareButton
                                        subject={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        body='Powered by SMITHBOOK.ORG'
                                        url={window.localStorage.getItem('path')}>
                                        <EmailIcon round={true}></EmailIcon>
                                    </EmailShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <FacebookShareButton
                                        url={window.localStorage.getItem('path')}
                                        quote={'Powered by SMITHBOOK'}
                                        hashtag='#SMITHBOOKORG'>
                                        <FacebookIcon round={true}></FacebookIcon>
                                    </FacebookShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <WorkplaceShareButton
                                        quote={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        hashtag='#SMITHBOOKORG'
                                        url={window.localStorage.getItem('path')}>
                                        <WorkplaceIcon round={true}></WorkplaceIcon>
                                    </WorkplaceShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <LinkedinShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}
                                        summary={window.localStorage.getItem('service_type')}
                                        source={window.localStorage.getItem('web_site')}>
                                        <LinkedinIcon round={true}></LinkedinIcon>
                                    </LinkedinShareButton>
                                </div>
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-button-section-div'>
                                <div className='share-social-card-form-button-div'>
                                    <TwitterShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        via={window.localStorage.getItem('service_type')}
                                        url={window.localStorage.getItem('path')}>
                                        <TwitterIcon round={true}></TwitterIcon>
                                    </TwitterShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <RedditShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <RedditIcon round={true}></RedditIcon>
                                    </RedditShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <WhatsappShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <WhatsappIcon round={true}></WhatsappIcon>
                                    </WhatsappShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <ViberShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <ViberIcon round={true}></ViberIcon>
                                    </ViberShareButton>
                                </div>
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-button-section-div'>
                                <div className='share-social-card-form-button-div'>
                                    <InstapaperShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        description='Powered by SMITHBOOK.ORG'
                                        url={window.localStorage.getItem('path')}>
                                        <InstapaperIcon round={true}></InstapaperIcon>
                                    </InstapaperShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <TelegramShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <TelegramIcon round={true}></TelegramIcon>
                                    </TelegramShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <PocketShareButton
                                        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <PocketIcon round={true}></PocketIcon>
                                    </PocketShareButton>
                                </div>
                                <div className='share-social-card-form-button-div'>
                                    <PinterestShareButton disabled disabledStyle={{ opacity: 0.2 }}
                                        media=''
                                        description={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
                                        url={window.localStorage.getItem('path')}>
                                        <PinterestIcon round={true}></PinterestIcon>
                                    </PinterestShareButton>
                                </div>
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            <div className='share-social-card-form-input-field-div'>
                                <Field readOnly className='share-social-card-form-input-field' type='text' name='empty_field' />
                            </div>
                            {window.localStorage.getItem('company_name')  === 'smithbook' ?  null : <AppLogoLinkButtonToHome />}
                            
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}

export default ShareSocialCard;

/*
<div className='share-social-card-form-button-div'>
    <HatenaShareButton disabled disabledStyle={ {opacity: 0.2} }
        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
        url={window.localStorage.getItem('path')}>
        <HatenaIcon round={true}></HatenaIcon>
    </HatenaShareButton>
</div>
<div className='share-social-card-form-button-div'>
    <VKShareButton disabled disabledStyle={{ opacity: 0.2 }}
        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
        image=''
        url={window.localStorage.getItem('path')}>
        <VKIcon round={true}></VKIcon>
    </VKShareButton>
</div>
<div className='share-social-card-form-button-div'>
    <TumblrShareButton disabled disabledStyle={{ opacity: 0.2 }}
        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
        caption={window.localStorage.getItem('service_type')}
        url={window.localStorage.getItem('path')}>
        <TumblrIcon round={true}></TumblrIcon>
    </TumblrShareButton>
</div>
<div className='share-social-card-form-button-div'>
    <LineShareButton disabled disabledStyle={{ opacity: 0.2 }}
        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
        url={window.localStorage.getItem('path')}>
        <LineIcon round={true}></LineIcon>
    </LineShareButton>
</div>
<div className='share-social-card-form-button-div'>
    <FacebookMessengerShareButton disabled disabledStyle={{ opacity: 0.2 }}
        url={window.localStorage.getItem('path')}>
        <FacebookMessengerIcon ></FacebookMessengerIcon>
    </FacebookMessengerShareButton>
</div>
<div className='share-social-card-form-button-div'>
    <LivejournalShareButton disabled disabledStyle={{ opacity: 0.2 }}
        title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
        description={window.localStorage.getItem('path')}
        url={window.localStorage.getItem('path')}>
        <LivejournalIcon round={true}></LivejournalIcon>
    </LivejournalShareButton>
</div>
<div className='share-social-card-form-button-div'>
<MailruShareButton disabled disabledStyle={{ opacity: 0.2 }}
    title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
    description={window.localStorage.getItem('service_type')}
    imageUrl=''
    url={window.localStorage.getItem('path')}>
    <MailruIcon round={true}></MailruIcon>
</MailruShareButton>
</div>
<div className='share-social-card-form-button-div'>
<OKShareButton disabled disabledStyle={{ opacity: 0.2 }}
    title={'Support Black Business Alert - ' + window.localStorage.getItem('company_name')}
    description={window.localStorage.getItem('service_type')}
    image=''
    url={window.localStorage.getItem('path')}>
    <OKIcon round={true}></OKIcon>
</OKShareButton>
</div>
*/