import React from 'react';
import { Link } from 'react-router-dom'
import { FaInstagram} from 'react-icons/fa'
import './ViewBusinessFormViewInstagramIconLinkButton.css'

function ViewBusinessFormViewInstagramIconLinkButton() {
    return (
        <div>
            <Link to={{
                pathname: '/viewwebsite',
                site: 'https://www.instagram.com/' + window.localStorage.getItem('instagram').substring(1)
             }} ><button type='button' className='view-business-form-instagram-icon-link-button'><FaInstagram id='grad1'/></button></Link>
        </div>
    );
}

export default ViewBusinessFormViewInstagramIconLinkButton;