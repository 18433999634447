import React from 'react';
import { Formik, Form } from 'formik'
//import FormikControl from '../.././formik/FormikControl'
import './LandingPageForm.css'
import LandingPageFormSignUpLinkButton from './LandingPageFormSignUpLinkButton'
import LandingPageFormLoginLinkButton from './LandingPageFormLoginLinkButton'
import AppLogoLinkButtonToHome from '../../AppLogoLinkButtonToHome'
import ReactPlayer from 'react-player'
//import LandingPageFormIpad from './LandingPageFormIpad'
//import { Field } from 'formik'
//import ViewSocialCard from './../ViewBusiness/ViewSocialCard'
import { useHistory } from 'react-router-dom'

function LandingPageForm() {

    const history = useHistory()
    function videoEnded() {
        history.push('/yournamehere')
    }
    return (
        <Formik>
            {
                //formik => {
                //return 
                <Form className='landing-page-form'>
                    <AppLogoLinkButtonToHome />

                    <div className='landing-page-form-react-player-div'>
                        <center><ReactPlayer
                                    url='https://fb.watch/6kiX41NVT7/'
                                    height='200px'
                                    width='100%'
                                    playing={true}
                                    onEnded={videoEnded}
                                    /></center>
                    </div>

                    <div>
                        <div className='landing-page-form-signup-link-button-div'>
                            <LandingPageFormSignUpLinkButton />
                        </div>
                        <div className='landing-page-form-login-link-button-div'>
                            <LandingPageFormLoginLinkButton />
                        </div>
                    </div>
                </Form>
                // }
            }
        </Formik>
    );
}

export default LandingPageForm;