import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPageFormLoginLinkButton() {

  return (
    <nav>
      <Link to='/login'><button className='error-page-form-login-link-button'>Log in</button></Link>
    </nav>
  );
}

export default ErrorPageFormLoginLinkButton