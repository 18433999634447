import React from 'react';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FaEthereum } from 'react-icons/fa'
import './ViewBusinessFormCopyEthereumAddressButtonIcon.css'

toast.configure()
function ViewBusinessFormCopyEthereumAddressButtonIcon() {

    const notify = () => {
        navigator.clipboard.writeText(window.localStorage.getItem('ethereum_address'))
        toast.success('eth address to clipboard',
            {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 4000
            })
    }
    return (
        <div>
            <button type='button' className='view-business-form-ethereum-icon-button' onClick={notify}><FaEthereum /></button>
        </div>
    );
}

export default ViewBusinessFormCopyEthereumAddressButtonIcon;